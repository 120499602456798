import { createContext, Component } from 'react';
import PropTypes            from 'prop-types';
import { getUnderscoredKeyMap } from 'data_format_utils'
import _                        from 'lodash';

export const contextName = 'RuleContext';
export const RuleContext = createContext();

export default class RuleProvider extends Component {
  constructor() {
    super();
    this.state = {
      rule: {},
      predicates: [],
      dataSourcesNamesMap: {},
      dataSourcesStrategiesMap: {},
      strategyNamesMap: {},
    }

    this.deletedPredicateIds = [];
    this.newCounter = 0;
  }

  // inits begin
  initFromProps = (propData) => {
    this.initialProps = propData;

    this.initRuleData();
    this.initMaps();

    return this.errorCount(propData);
  }

  revertChanges = () => {
    this.initRuleData();

    this.newCounter = 0;
    this.deletedPredicateIds = [];
  }

  // updates begin

  updateRule = mergeObj => {
    const { rule } = this.state;
    this.setState({
      rule: {
        ...rule,
        ...mergeObj
      }
    });
  }

  addPredicate = () => {
    const { predicates } = this.state;
    const newPredicates = [
      ...predicates,
      this.processPredicate({
        id: null,
        valueSpec: null,
        strategyType: null,
        criteriaSpec: null,
      })
    ];

    this.setState({ predicates: newPredicates });
  }

  removePredicate = (predicate) => {
    if (!predicate.newRecord) {
      this.deletedPredicateIds.push(predicate.id);
    }
    const predicates = _.reject(this.state.predicates, p => p.id === predicate.id);
    this.setState({ predicates });
  }

  updatePredicate = (predicateId, mergeObj) => {
    const { predicates } = this.state;
    const oldPredicate = _.find(predicates, predicate => predicate.id === predicateId);
    const newPredicate = {
      ...oldPredicate,
      ...mergeObj
    };

    const newPredicates = _.map(
      predicates,
      predicate => predicate.id === newPredicate.id ? newPredicate : predicate
    );

    this.setState({ predicates: newPredicates });
  }

  // getters begin

  savePayload = () => {
    const { rule, rule: { id }, predicates } = this.state;

    const predicateMessages = _.map(
      predicates,
      predicate => predicate.newRecord ? {...predicate, id: null} : predicate
    );

    return {
      id,
      rule,
      predicates: predicateMessages,
      deletedPredicateIds: this.deletedPredicateIds
    };
  }

  errorCount(data) {
    const ruleErrors = _.values(data.rule.errors || {});
    const predicates = data.rule.predicates || [];
    const predicateErrors = _.map(predicates, p => _.values(p.errors || {}));

    return _.flatten([ruleErrors, predicateErrors]).length;
  }

  // private begins

  processPredicate = predicate => {
    return predicate.id === null ?
    {
      ...predicate,
      id: `n${this.newCounter++}`,
      newRecord: true,
    } :
    predicate;
  }

  initRuleData = () => {
    const { rule, rule: { predicates } } = this.initialProps;

    this.setState({
      rule,
      predicates: _.map(predicates, this.processPredicate)
    });
  }

  initMaps = () => {
    if (this.nameMaps) return;

    const {
      dataSourcesNamesMap,
      dataSourcesStrategiesMap,
      strategyNamesMap
    } = this.initialProps;

    this.nameMaps = {
      dataSourcesNamesMap: getUnderscoredKeyMap(dataSourcesNamesMap),
      dataSourcesStrategiesMap: getUnderscoredKeyMap(dataSourcesStrategiesMap),
      strategyNamesMap: getUnderscoredKeyMap(strategyNamesMap),
    }
  }

  render() {
    return (
      <RuleContext.Provider
        value={{
          initFromProps: this.initFromProps,
          savePayload: this.savePayload,
          dataSourcesNamesMap: this.dataSourcesNamesMap,
          dataSourcesStrategiesMap: this.dataSourcesStrategiesMap,
          strategyNamesMap: this.strategyNamesMap,
          addPredicate: this.addPredicate,
          updatePredicate: this.updatePredicate,
          removePredicate: this.removePredicate,
          revertChanges: this.revertChanges,
          predicates: this.state.predicates,
          rule: this.state.rule,
          updateRule: this.updateRule,
          nameMaps: this.nameMaps,
        }}
      >
        {this.props.children}
      </RuleContext.Provider>
    )
  }
}

RuleProvider.propTypes = {
  children: PropTypes.node,
}
