import { createContext, useState } from 'react';
import PropTypes           from 'prop-types';
import { post, put, del }  from 'ajax';

export const contextName = 'AddAdminContext';
export const AddAdminContext = createContext();

export default function AddAdminProvider(props) {
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [nonAdmins, setNonAdmins] = useState([]);
  const [permissions, setPermissions] = useState(DEFAULT_STATE);

  const initData = (permissions = DEFAULT_STATE) => {
    setPermissions(permissions);
    setNonAdmins(INITIAL_DATA.nonAdmins || []);
  }

  const cleanPermissions = () => {
    setPermissions(DEFAULT_STATE);
  }

  const updatePermissions = (permissionName, dependents = []) => {
    const newPerms = Object.assign({}, permissions, {
      [permissionName]: !permissions[permissionName],
    });

    if (newPerms[permissionName]) {
      dependents.forEach(dependentPerm => {
        newPerms[dependentPerm] = true;
      });
    }

    setPermissions(newPerms);
  }

  const updateAll = (bool) => {
    const newPerms = { ...permissions };
    Object.keys(newPerms).forEach(newPerm => {
      newPerms[newPerm] = bool;
    });

    setPermissions(newPerms);
  }

  const selectAll = () => {
    updateAll(true);
  }

  const removeAll = () => {
    updateAll(false);
  }

  const savePermissions = (cb) => {
    post(`/permissions/admin/${selectedAdmin.uuid}`, { permissions }, res => {
      setNonAdmins(res.nonAdmins);
      cb(res.admins, res.permissionsMap);
    });
  }

  const updateMembersPermissions = (cb, uuid) => {
    put(`/permissions/admin/${uuid}`, { permissions }, res => {
      cb(res.memberPermissions);
    });
  }

  const addInternalPermissions = (cb, uuid) => {
    post(`/internal/permissions/user/${uuid}`, { permissions }, res => {
      cb(res.permissions);
    });
  }

  const deleteAdmin = (cb, uuid) => {
    del(`/permissions/admin/${uuid}`, { permissions }, res => {
      cb(res.memberPermissions);
    });
  }

  const deleteInternalAdmin = (cb, uuid) => {
    del(`/internal/permissions/user/${uuid}`, {}, res => {
      cb(res.memberPermissions);
    });
  }

  return (
    <AddAdminContext.Provider
      value={{
        updatePermissions,
        updateMembersPermissions,
        addInternalPermissions,
        setSelectedAdmin,
        selectedAdmin,
        permissions,
        savePermissions,
        deleteAdmin,
        deleteInternalAdmin,
        nonAdmins,
        initData,
        selectAll,
        removeAll,
        cleanPermissions,
      }}
    >
      {props.children}
    </AddAdminContext.Provider>
  )
}

const DEFAULT_STATE = {
  // internal perms
  editPermissions: false,
  editCompanies: false,
  editPlanInfo: false,
  activateQuotes: false,
  viewQuotes: false,
  createPrelimQuotes: false,
  adjustQuotes: false,
  submitCurv: false,
  manageInvoicing: false,
  manageOpenEnrollment: false,
  manageDocuments: false,
  viewClaims: false,
  processClaims: false,
  viewStoplossClaims: false,
  processStoplossClaims: false,
  manageRulesEngine: false,
  viewSensitiveMemberInfo: false,
  logInAsMembers: false,
  addDeductibleCredit: false,
  viewAndDownloadReports: false,
  // company perms
  editAdminPermissions: false,
  manageEmployees: false,
  manageEnrollments: false,
  viewEmployees: false,
  viewInvoices: false,
  viewPlanSpend: false,
};

AddAdminProvider.propTypes = {
  children: PropTypes.node,
}
