import { Component } from 'react';
import PropTypes            from 'prop-types';
import classnames           from 'classnames';

import svgList from './svg-list';

export default class Icon extends Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    viewBox: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    viewBox: '0 0 24 24',
    scale: 1,
    role: 'img',
    tabIndex: '-1',
  }

  render() {
    const {
      name,
      className,
      viewBox: defaultViewBox,
    } = this.props;

    const shape = svgList[name];
    const viewBox = shape && shape.viewBox ? shape.viewBox : defaultViewBox;

    return (
      <svg
        className={classnames('Icon', className)}
        {...this.props}
        viewBox={viewBox}
      >
        { shape && shape.defs }
        <g>
          { shape.shape }
        </g>
      </svg>
    )
  }
}
