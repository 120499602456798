import { createContext, Component } from 'react';
import PropTypes            from 'prop-types';

export const contextName = 'PermissionsContext';
export const PermissionsContext = createContext();

export default class PermissionsProvider extends Component {
  state = {
    permissions: INITIAL_DATA.permissions
  }

  updatePermissions = (changedPerm) => {
    const permissions = Object.assign({}, this.state.permissions, changedPerm)
    this.setState({ permissions });
  }

  getPermission = (permission) => {
    return this.state.permissions[permission] || false;
  }

  render() {
    return (
      <PermissionsContext.Provider
        value={{
          updatePermissions: this.updatePermissions,
          getPermission: this.getPermission,
        }}
      >
        {this.props.children}
      </PermissionsContext.Provider>
    )
  }
}

PermissionsProvider.propTypes = {
  children: PropTypes.node,
}
