import { createContext, Component } from 'react';

export const contextName = 'ClaimChargesCSVContext';
export const ClaimChargesCSVContext = createContext();

export default class ClaimChargesCSVProvider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filePath: null,
      fileName: null,
      displayName: null,
      chargeTypes: {},
      chargeType: '',
      remittance: '',
      useMostRecentEnrollment: false,
      isUploading: false,
      uploadErrors: [],
    }
  }

  updateDisplayName = (displayName) => {
    this.setState({ displayName });
  }

  updateChargeTypes = (chargeTypes) => {
    this.setState({ chargeTypes });
  }

  updateChargeType = (chargeType) => {
    this.setState({ chargeType });
  }

  updateRemittance = (remittance) => {
    this.setState({ remittance });
  }

  toggleUseMostRecentEnrollment = () => {
    this.setState({ useMostRecentEnrollment: !this.state.useMostRecentEnrollment });
  }

  updateFile = (filePath) => {
    const fileName = filePath.split('\\').pop();
    this.setState({
      filePath,
      fileName,
      displayName: fileName,
    });
  }

  clearFile = () => {
    this.setState({
      filePath: null,
      fileName: null,
      displayName: null,
    })
  }

  clearErrors = () => {
    this.setState({uploadErrors: []})
  }

  handleSuccess = () => {
    this.clean();
  }

  handleErrors = (errors) => {
    this.setState({
      filePath: null,
      fileName: null,
      displayName: null,
      uploadErrors:  [...this.state.uploadErrors, ...errors]
    });
  }

  toggleIsUploading = () => {
    this.setState({ isUploading: !this.state.isUploading });
  }

  clean = () => {
    this.setState({
      filePath: null,
      fileName: null,
      displayName: null,
      chargeType: '',
      remittance: '',
      useMostRecentEnrollment: false,
      isUploading: false,
      uploadErrors: [],
    });
  }

  render() {
    return (
      <ClaimChargesCSVContext.Provider
        value={{
          ...this.state,
          updateFile: this.updateFile,
          clearFile: this.clearFile,
          updateDisplayName: this.updateDisplayName,
          updateChargeTypes: this.updateChargeTypes,
          updateChargeType: this.updateChargeType,
          updateRemittance: this.updateRemittance,
          toggleUseMostRecentEnrollment: this.toggleUseMostRecentEnrollment,
          toggleIsUploading: this.toggleIsUploading,
          handleErrors: this.handleErrors,
          handleSuccess: this.handleSuccess,
          clean: this.clean,
          clearErrors: this.clearErrors,
        }}
      >
        {this.props.children}
      </ClaimChargesCSVContext.Provider>
    );
  }
}
