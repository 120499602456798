import "./Button.scss";
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHover } from "general_hooks"

const ButtonBase = forwardRef((
  {
    size = "large",
    variant = "primary",
    iconAlignment = "right",
    tertiaryColor,
    icon,
    block,
    disabled,
    loading,
    children,
    dataTrackingId,
    dataTestId,
    className,
    render,
    "aria-label": ariaLabel,
  }, ref) => {
  const [isHovering, onMouseEnter, onMouseLeave] = useHover();
  const isDisabled = disabled || loading;

  const classes = {
    "button--primary": variant === "primary",
    "button--secondary": variant === "secondary",
    "button--tertiary": variant === "tertiary",
    "button--marketing-style": variant === "marketing-style",
    "button--link": variant === "link",
    "button--v1": ["v1", "v1-blue", "v1-red"].includes(variant),
    "button--v1-blue": ["v1", "v1-blue"].includes(variant),
    "button--v1-red": variant === "v1-red",
    "button--block": block,
    "button--large": size === "large",
    "button--small": size === "small",
    "button--icon-only": !children && icon,
    "button--icon-left": icon && iconAlignment === "left",
    "button--icon-right": icon && iconAlignment === "right",
  }

  const classNames = classnames("button", classes, className);

  // Workaround for setting dynamic tertiary
  // hover background color as inline selectors
  // such as :hover are not supported
  function tertiaryHoverStyle() {
    if (variant === "tertiary" && !tertiaryColor && isHovering) {
      return { backgroundColor: "#ECF4FD" };
    }

    return variant === "tertiary" && !!tertiaryColor && isHovering ? { backgroundColor: tertiaryColor } : null;
  }

  const shared = {
    disabled: isDisabled,
    className: classNames,
    style: tertiaryHoverStyle(),
    onMouseEnter,
    onMouseLeave,
    "data-tracking-id": dataTrackingId,
    "data-testid": dataTestId,
    ref,
    "aria-label": ariaLabel,
  };

  const contents = <>
    {children && <span className="button--container">{children}</span>}
    {icon}
  </>;

  return render(shared, contents);
});

const Button = hoc('button', ['onClick', 'type']);

Button.displayName = "Button"


export function hoc(Component, componentPropNames) {
  return function(_props) {
    const props = { ..._props };

    const componentProps = {};

    componentPropNames.forEach(p => {
      componentProps[p] = props[p];
      delete props[p];
    });

    // Note we pass along children to the base component in ...props
    return <ButtonBase { ...props } render={ (shared, content) => (
      <Component { ...shared } { ...componentProps }>{ content }</Component>
    ) } />
  }
}

export const sharedProps = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(["large", "small"]),
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "marketing-style", "link", "v1", "v1-blue", "v1-red"]),
  icon: PropTypes.node,
  iconAlignment: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dataTrackingId: PropTypes.string,
  dataTestId: PropTypes.string,
  block: PropTypes.bool,
  tertiaryColor: PropTypes.string,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
}

ButtonBase.propTypes = sharedProps;

Button.propTypes = {
  ...sharedProps,
  type: PropTypes.oneOf(["submit", "reset", "button"]),
  onClick: PropTypes.func,
};

export default Button;
