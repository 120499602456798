import './RegisterDependentView.scss'

import { Component } from 'react';
import PropTypes            from 'prop-types'
import { post }        from 'ajax'
import { capitalize }      from 'lodash';

import RegisterVerification from '../../components/RegisterVerification/RegisterVerification';
import RegisterEmailPassword from '../../components/RegisterEmailPassword';

export default class RegisterDependentView extends Component {
  static propTypes = {
    person: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      registrationToken: PropTypes.string.isRequired
    }),
    currentStep: PropTypes.string,
    email: PropTypes.string
  }

  state = {
    currentStep: this.props.currentStep || 'verification',
    birthDay: null,
    birthMonth: null,
    birthYear: null,
    ssn: '',
    email: this.props.email || '',
    password: '',
    passwordConfirmation: '',
    paperlessCommunication: true,
  }

  updateForm = (attr, val) => {
    const newState = {};
    newState[attr] = val;
    this.setState(newState);
  }

  updateDate = (dateType, part, val) => {
    const attr = `${dateType}${capitalize(part)}`;
    this.updateForm(attr, val);
  }

  onSubmit = (evt) => {
    evt.preventDefault();
    const { birthDay, birthMonth, birthYear, ssn, email, password, passwordConfirmation, currentStep } = this.state;

    switch (currentStep) {
      case 'verification': {
        const birthdayString = [birthYear, birthMonth, birthDay].join("-");
        const ssnString = ssn.replace(/-/g, "");
        const jsonData = { birthday: birthdayString, ssn: ssnString };
        post(`${window.location.pathname}/verify`, jsonData, (response) => {
          if (response.errors) {
            this.setState({ errors: response.errors });
          } else if (response.success) {
            this.setState({ currentStep: 'registerEmailPassword' })
          }
        });
        break;
      }
      case 'registerEmailPassword': {
        const jsonData = { email, password, passwordConfirmation };
        post(`${window.location.pathname}/create`, jsonData, (response) => {
          if (response.errors) {
            this.setState({ errors: response.errors });
          } else if (response.success) {
            window.location = '/?finishing_registration=1';
          }
        });
        break;
      }
    }
  }

  renderStep = () => {
    const {
      birthDay,
      birthMonth,
      birthYear,
      ssn,
      email,
      password,
      passwordConfirmation,
      currentStep,
      errors } = this.state;
    const { firstName } = this.props.person;

    switch (currentStep) {
      case 'verification':
        return <RegisterVerification
          firstName={firstName}
          birthDay={birthDay}
          birthMonth={birthMonth}
          birthYear={birthYear}
          ssn={ssn}
          handleSubmit={this.onSubmit}
          updateForm={this.updateForm}
          updateDate={this.updateDate}
          errors={errors}
        />
      case 'registerEmailPassword':
        return <RegisterEmailPassword
          email={email}
          password={password}
          passwordConfirmation={passwordConfirmation}
          errors={errors}
          updateForm={this.updateForm}
          handleSubmit={this.onSubmit}
        />
    }
  }

  render() {
    return <div className="RegisterDependentView">{this.renderStep()}</div>
  }
}
