import './ForgotPasswordView.scss'

import { Component } from 'react';
import PropTypes            from 'prop-types'
import { post }             from 'ajax';

import Form         from 'Form'
import Input        from 'Input'
import Button       from 'Button'
import ErrorMessage from 'ErrorMessage'


export default class ForgotPasswordView extends Component {
  static propTypes = {
    redirect: PropTypes.string
  }
  state = {
    email: "",
    errors: [],
    submitted: false,
    success: false
  }

  handleUpdateEmail = email => {
    this.setState({ email })
  }

  handleSubmit = event => {
    event.preventDefault()

    const { redirect } = this.props;
    const { email }    = this.state;

    this.setState({ submitted: true, errors: [] })
    post('/forgot-password', { email }, (res, status) => {
      if (status === 200) {

        if (redirect) {
          window.location = `/register/${redirect}?email=${email}`;
        } else {
          this.setState({ success: true });
        }
      } else {
        this.setState({
          errors: ["Something went wrong. Please try again."]
        })
      }
    })
  }

  componentDidMount() {
    // auto-focus
    this.emailInput.input.focus()
  }

  render() {
    const { email, errors, submitted, success } = this.state;

    return (
      <div className="ForgotPasswordView">
        {(() => {
          if (success) {
            return (
              <div className="success">
                <h1>
                  Password Reset Request Sent!
                </h1>
                <p className="instructions">
                  Check your email for instructions and a link to reset your password.
                </p>
              </div>
            )
          } else {
            return (
              <div>
                <h1>Forgot your password? We got you.</h1>
                <Form method="POST" onSubmit={this.handleSubmit}>
                  <p className="instructions">
                    Enter your email address below, and we'll send you a password reset link.
                  </p>
                  <Input
                    type="email"
                    name="email"
                    label="Email Address"
                    value={email}
                    onChange={this.handleUpdateEmail}
                    ref={input => this.emailInput = input}
                  />
                  <span className="registration-note">Not sure which email address to use? Try your personal email first, as this is the one we recommended during registration. </span>
                  <Button
                    type="submit"
                    disabled={submitted}
                  >
                    Request Password Reset
                  </Button>
                  <ErrorMessage errors={errors} />
                </Form>
              </div>
            )
          }
        })()}
      </div>
    )
  }
}
