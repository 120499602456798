import './ResetPasswordView.scss'

import { Component } from 'react';
import PropTypes            from 'prop-types'
import { put }              from 'ajax'

import Form         from 'Form'
import Input        from 'Input'
import Button       from 'Button'
import ErrorMessage from 'ErrorMessage'


export default class ResetPasswordView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        uuid: PropTypes.string.isRequired
      }).isRequired
    })
  }

  state = {
    password: "",
    passwordConfirmation: "",
    errors: {},
    submitted: false,
    success: false
  }

  handleUpdate = (attr, value) => {
    const newState = {}
    newState[attr] = value;
    this.setState(newState)
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ submitted: true, errors: {} })

    const { password, passwordConfirmation } = this.state;
    const { uuid } = this.props.match.params;

    put(`/forgot-password/${uuid}`, {
      passwordResetToken: { password, passwordConfirmation }
    }, (res, status) => {
      if (status === 200) {
        // redirect to the login view
        window.location = '/login?password_reset_success=1';
      } else {
        this.handleError(res.passwordResetToken.errors);
      }
    })

  }

  handleError = errors => {
    this.setState({
      submitted: false,
      errors
    })
  }

  componentDidMount() {
    // auto-focus
    this.passwordInput.input.focus()
  }

  render() {
    const {
      password,
      passwordConfirmation,
      errors,
      submitted
    } = this.state;

    return (
      <div className="ResetPasswordView">
        <h1 className="reset-password-header">Reset Password</h1>
        <p className="instructions">
          Choose a new password and enter it below.
        </p>
        <Form method="POST" onSubmit={this.handleSubmit} errors={errors}>
          <Input
            type="password"
            label="New Password"
            value={password}
            onChange={val => this.handleUpdate('password', val)}
            ref={input => this.passwordInput = input}
            errorKey="password"
          />
          <Input
            type="password"
            label="Confirm New Password"
            className="password-confirmation-input"
            value={passwordConfirmation}
            onChange={val => this.handleUpdate('passwordConfirmation', val)}
            errorKey="passwordConfirmation"
          />
          <div className="base-errors">
            <ErrorMessage errors={errors.base} />
          </div>
          <Button
            type="submit"
            disabled={submitted}
          >
            Set New Password
          </Button>
        </Form>
      </div>
    )
  }
}
