import './RegisterError.scss'

import { Component } from 'react';

import Button       from 'ButtonV2'
import Textlink     from 'Textlink'

export default class RegisterError extends Component {

  render() {
    return (
      <div className="RegisterError">
        <div className="img-container">
          <img src="/images/register/hiker-man.png" />
        </div>
        <h1>Oops — this link belongs to another user.</h1>
        <p>
          Please ask the main subscriber or your plan administrator to re-send your invite. Need additional help? Contact us at 
          <Textlink href="mailto:hello@sanabenefits.com" target="_self">
            hello@sanabenefits.com
          </Textlink>.
        </p>
        <Button
          onClick={() => window.location.href = '/dashboard'}
          size="large"
          variant="primary"
        >
          Exit Now
        </Button>
      </div>
    )
  }

}
