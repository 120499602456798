import "./AppLayout.scss"

import { useState, useEffect, useLayoutEffect, useContext } from 'react';
import PropTypes            from 'prop-types'
import classnames           from 'classnames'
import { MasterContext }    from 'master_context'

import Icon                 from 'Icon'
import SideNav              from 'SideNav'
import Navigation           from 'Navigation'

import Logo                 from 'images/secondary_logo'
import KebabIcon            from './icons/kebab'
import HamburgerIcon        from './icons/hamburger'
import FlashMessageQueue    from 'FlashMessageQueue'

export default function AppLayout({ children }) {
  const [adminCompanies] = useState(INITIAL_DATA.adminCompanies);
  const [isBroker] = useState(INITIAL_DATA.isBroker);
  const [bundleName] = useState(INITIAL_DATA.bundleName);
  const [currentUser] = useState(INITIAL_DATA.currentUser);
  const [currentCompany] = useState(INITIAL_DATA.currentCompany);
  const [loggedInAs] = useState(INITIAL_DATA.loggedInAs);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [navigationVisible, setNavigationVisible] = useState(false);
  const [offerings] = useState(INITIAL_DATA.navOfferings);
  const [internalEternalVisibleNav] = useState(INITIAL_DATA.internalEternalVisibleNav);
  const [permissions] = useState(INITIAL_DATA.permissions);

  const { PopoverContext, ShowNavContext, WindowSizeContext } = useContext(MasterContext);
  const { popover } = PopoverContext;
  const { handleToggleMoreNav } = ShowNavContext;
  const { updateWidth } = WindowSizeContext;

  const {
    firstNameOrEmail,
    nickname,
  } = currentUser;

  const friendlyName = nickname || firstNameOrEmail.split('@')[0];
  const shortenedName = friendlyName.length > 12 ? `${friendlyName.slice(0, 12)}...` : friendlyName;

  const enableNewNavigation = bundleName !== "InternalApp";
  const isInternal = bundleName === "InternalApp";

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const handleHideDropdown = () => {
    setShowUserDropdown(false);
  }

  const handleToggleNavigationVisible = () => {
    setNavigationVisible(!navigationVisible);
  }

  const hasAdminMember = () => {
    return adminCompanies.length > 0
  }

  return (
    <>
      <div
        className={classnames(
          "AppLayout",
          'AppLayout--default-content-sizing',
          {"updated-navigation": enableNewNavigation},
          {"internal-eternal-visible-nav": internalEternalVisibleNav}
        )}
        onClick={handleHideDropdown}
      >
        {enableNewNavigation ?
          <Navigation
            currentRoute={window.location.pathname}
            isAdmin={hasAdminMember()}
            bundleName={bundleName}
            visible={navigationVisible}
            handleToggleNavigationVisible={handleToggleNavigationVisible}
            offerings={offerings}
            currentCompany={currentCompany}
            adminCompanies={adminCompanies}
            permissions={permissions}
            isBroker={isBroker}
          />
          :
          <SideNav
            bundleName={bundleName}
            hasAdminMember={hasAdminMember}
            permissions={permissions}
            shortenedName={shortenedName}
          />
        }
        <div className="page-content">
          {(() => {
            if (loggedInAs) {
              return (
                <div className="logged-in-as-message">
                  You are currently logged in as {currentUser.fullNameOrEmail} (User {currentUser.id}). Log out to return to internal tools.
                </div>
              )
            }
          })()}
          <header className="global-top">
            <div className="top-nav">
              { isInternal &&
                <div className="logo-wrapper">
                  <a href="/">
                    <Logo className="logo" />
                  </a>
                </div>
              }
              <div className="right-group">
                <button className="current-user-dropdown-toggle"
                  onMouseEnter={() => setShowUserDropdown(true)}
                  onMouseLeave={() => setShowUserDropdown(false)}
                >
                  {shortenedName}
                  <Icon name="dropdownArrow" className="dropdown-arrow" />
                </button>
                <div className={classnames('current-user-dropdown', { show: showUserDropdown })}
                  onMouseEnter={() => setShowUserDropdown(true)}
                  onMouseLeave={() => setShowUserDropdown(false)}
                >
                  {!enableNewNavigation &&
                    <a className="dropdown-link" href="https://sanabenefits.zendesk.com/hc/en-us">Help Center</a>
                  }

                  <a className="dropdown-link" href="/account-settings">Settings & Profile</a>

                  <a className="dropdown-link" href="/logout">Log Out</a>
                </div>
                {enableNewNavigation ?
                  <button className="hamburger-icon" onClick={handleToggleNavigationVisible}>
                    <HamburgerIcon className="icon" />
                  </button> :
                  <button className="kebab-icon" onClick={handleToggleMoreNav}>
                    <KebabIcon className="icon" />
                  </button>
                }
              </div>
            </div>
          </header>
          <main className="content-wrapper">
            <FlashMessageQueue />
            {children}
          </main>
          <footer className="page-footer">
            <div className="inner">
              <div className="help-info">
                <span className="need-help">
                  <span className="need-help-1">Need help? Call us anytime:</span>
                </span>
                <span className="phone-number">(833) 726-2123</span>
              </div>

              <div className="footer-nav">
                <span className="copyright">
                  Sana Benefits © {new Date().getFullYear()}
                </span>
                <a href="https://sanabenefits.com/terms-of-service">Terms</a>
                <a href="https://sanabenefits.com/privacy-policy">Privacy</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      { popover }
    </>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  mobileStore: PropTypes.shape({
    updateWidth: PropTypes.func,
  }),
}

// Okay, if anybody else has set this, removing it will break. Hmm.
// Raise an error if more than one person sets it?
// TODO: do all unmount effects run before mount effects?
export function useLockedHeightAppLayout() {
  useLayoutEffect(() => {
    document.querySelector('.page-content').classList.add("page-content--locked-height");

    return () => {
      document.querySelector('.page-content').classList.remove("page-content--locked-height");
    };
  }, [])
}

export function useCustomContentPadding() {
  useLayoutEffect(() => {
    document.querySelector('.AppLayout').classList.remove("AppLayout--default-content-sizing");

    return () => {
      document.querySelector('.AppLayout').classList.add("AppLayout--default-content-sizing");
    };
  }, [])
}
