import "./SmartView.scss"
import 'url-search-params-polyfill';

import { Children, cloneElement, Component } from 'react';
import PropTypes            from 'prop-types'
import ajax                 from 'ajax'
import FlashStore           from 'flash_store'

import LoadingSpinner from 'LoadingSpinner'

export default class SmartView extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired
  }

  constructor(props) {
    super(props);

    if (this.initialPageLoaded()) {
      this.state = {
        dataReceived: false
      };
    } else {
      this.state = Object.assign({}, window.INITIAL_DATA, {
        dataReceived: true
      });
    }
  }

  saveFetchedData = data => {
    this.setState(Object.assign({}, data, {
      dataReceived: true
    }))
  }

  setFlashMessage() {
    if (this.state.flash && this.state.flash.length > 0) {
      const flash = this.state.flash[0];
      const flashType = flash[0];
      const flashMessage = flash[1];
      FlashStore.add(flashType, flashMessage);
    }
  }

  updateState = (updates, callback) => {
    this.setState(updates, callback)
  }

  initialPageLoaded() {
    return window.INITIAL_PAGE_LOADED;
  }

  componentDidMount() {
    if (this.initialPageLoaded()) {
      const url = window.location.pathname;
      const params = new URLSearchParams(window.location.search);
      const queryData = {};
      for (let key of params.keys()) {
        queryData[key] = params.get(key);
      }

      ajax.request(url, 'get', {}, { params: queryData, htmlErrors: true }).then((response) => {
        this.saveFetchedData(response)
      }).catch((error) => {
        if (error.response && error.response.status === 404) {
          window.location.href = '/not-found';
          return;
        }

        if (process.env.NODE_ENV === "development") {
          if (error.response && error.response.status === 500) {
            // This doesn't handle the browser going back elegantly, it should reload the react app
            // or store the data and return it here
            document.getElementsByTagName('body')[0].innerHTML = error.response.data;
          } else {
            alert("Page would have redirected to oops!");
          }
        } else {
          // NOTE: temporary catch-all for now. will handle
          // specific errors if needed in the future
          window.location.href = '/oops';
        }
      });
    } else {
      window.INITIAL_PAGE_LOADED = true;
    }

    window.scrollTo(0, 0);
    this.setFlashMessage();
  }

  render() {
    const { dataReceived } = this.state;
    const { children } = this.props;

    const childrenWithProps = Children.map(children, child => {
      return cloneElement(child, Object.assign({}, this.state, {
        updateState: this.updateState
      }));
    })

    return (
      <div className="SmartView">
        {(() => {
          if (dataReceived) {
            return childrenWithProps;
          } else {
            return (
              <LoadingSpinner className="smart-view-spinner" />
            )
          }
        })()}
      </div>
    )
  }
}
