import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import AppLayout                   from 'AppLayout';
import MasterProviderWrapper       from 'master_context';

import addAccessibilityListeners   from './accessibility';


// Attach an app to the DOM
function renderApp(app) {
  addAccessibilityListeners();
  const container = document.getElementById("react_app");
  const root = createRoot(container);
  root.render(app);
}


export default function bootstrap(appComponent, optionsParam = {}) {
  const defaults = {
    nav: {},
    layout: AppLayout
  }
  const options = Object.assign({}, defaults, optionsParam);

  document.addEventListener('DOMContentLoaded', () => {
    const app = (
      <Router>
        <MasterProviderWrapper>
          <options.layout>
            {appComponent}
          </options.layout>
        </MasterProviderWrapper>
      </Router>
    );

    renderApp(app);
  });
}
