import './NotActiveView.scss'

import { Component } from 'react';

export default class NotActiveView extends Component {
  render() {
    return (
      <div className="NotActiveView">
        <h1>Your account is not yet active</h1>
        <p>If your company is in the process of switching to Sana then you can expect an invitation to join via email soon.</p>
        <p>If you'd like to learn more about Sana's employee health benefits or learn about pricing, please <a href="https://sanabenefits.com/get-quote">request a quote</a>.</p>
        <p className="logout">
          <a href="/logout">Log out</a>
        </p>
      </div>
    )
  }
}
