import './LoginApp.scss'

import { Component } from 'react';
import { Route, Switch }    from 'react-router-dom'
import { wrapComponent }    from 'SmartView'

import Login          from './views/LoginView'
import ForgotPassword from './views/ForgotPasswordView'
import ResetPassword  from './views/ResetPasswordView'
import RegisterDependent from './views/RegisterDependentView'
import RegisterMember from './views/RegisterMemberView'
import NotActive      from './views/NotActiveView'
import Unsubscribe    from './views/UnsubscribeView'
import NpsScore       from './views/NpsScoreView'

export default class LoginApp extends Component {
  render() {
    return (
      <div className="LoginApp">
        <Switch>
          <Route path="/nps/:uuid/score/:score" component={wrapComponent(NpsScore)} />
          <Route path="/unsubscribe/:uuid" component={wrapComponent(Unsubscribe)} />
          <Route path="/forgot-password/:uuid" component={ResetPassword} />
          <Route path="/forgot-password" component={wrapComponent(ForgotPassword)} />
          <Route path={["/register/send_link", "/register/:uuid/member/"]} component={wrapComponent(RegisterMember)} />
          <Route path="/register/:uuid/dependent/" component={wrapComponent(RegisterDependent)} />
          <Route path="/not-active" component={wrapComponent(NotActive)} />
          <Route path="/login" component={wrapComponent(Login)} />
          <Route path="/" component={wrapComponent(Login)} />
        </Switch>
      </div>
    )
  }
}
