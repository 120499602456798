import { createContext, useState } from 'react';
import PropTypes           from 'prop-types';
import { capitalize }      from 'lodash';
import { get, post }       from 'ajax';

export const contextName = 'NewEmployeeContext';
export const NewEmployeeContext = createContext();

export default function NewEmployeeProvider(props) {
  const [newMember, setNewMember] = useState(props.newMember || DEFAULT_STATE);
  const [taxonomies, setTaxonomies] = useState(props.taxonomies || []);
  const [taxonomyOptions, setTaxonomyOptions] = useState(props.taxonomyOptions || {});

  const fetchData = (cb) => {
    get('/company/taxonomies', {}, res => {
      setTaxonomies(res.requiredTaxonomies);
      setTaxonomyOptions(setDefaultTaxonomyOptions(res.requiredTaxonomies));

      cb(res);
    });
  }

  const createNewEmployee = async onError => {
    const params = getParams();

    post('/members', params)
      .then(rsp => {
        const url = `/members/${rsp.member.uuid}?newEmployee=true`
        window.location.href = url;
      })
      .catch(error => {
        if ([400, 422].includes(error.response.status)) {
          const { errors } = error.response.data;
          onError(errors);
          addErrors(errors);
        } else {
          onError({ member: {base: ['Something went wrong. Please try again later.  If the problem persists, please contact support.'] }})
        }
      })
  }

  const getParams = () => {
    const member = {
      firstName: newMember.firstName,
      lastName: newMember.lastName,
      workEmail: newMember.workEmail,
      sex: newMember.sex,
      ssn: newMember.ssn ? newMember.ssn.replace(/-/g, '') : '',
      dateOfBirth: `${newMember.birthYear}-${newMember.birthMonth}-${newMember.birthDay}`,
      hireDate: `${newMember.hireDateYear}-${newMember.hireDateMonth}-${newMember.hireDateDay}`,
    };

    const address = {
      address1: newMember.address1,
      address2: newMember.address2,
      city: newMember.city,
      state: newMember.state,
      zip: newMember.zip,
    }

    const memberTaxonomyAssignments = Object.keys(taxonomyOptions).length > 0 ? taxonomyOptions : null;


    let salary = {};

    if (newMember.salaryFrequency) {
      salary = {
        amount: newMember.salary ? parseFloat(newMember.salary.replace(/,/g, '')) * 100 : null,
        salaryType: newMember.salaryFrequency,
        jobTitle: newMember.jobTitle,
        hoursPerWeek: newMember.weeklyHours,
      }
    }

    return {
      member,
      address,
      memberTaxonomyAssignments,
      salary,
    };
  }

  const addErrors = errors => {
    const updates = { errors };
    const updatedState = Object.assign({}, newMember, updates);
    setNewMember(updatedState);
  }

  const handleBulkUpdateForm = (updatedFields) => {
    const updatedState = Object.assign({}, newMember, updatedFields);
    setNewMember(updatedState);
  }

  const handleUpdateForm = (attr, val) => {
    const updates = {};
    updates[attr] = val;
    const updatedState = Object.assign({}, newMember, updates);
    setNewMember(updatedState);
  }

  const handleUpdateDate = (dateType, part, val) => {
    const attr = `${dateType}${capitalize(part)}`
    handleUpdateForm(attr, val, dateType)
  }

  const handleUpdateTaxonomy = (uuid, val) => {
    const updatedValue = { [uuid]: val };
    const newState = Object.assign({}, taxonomyOptions, updatedValue);
    setTaxonomyOptions(newState);
  }

  const clearData = () => {
    setNewMember(DEFAULT_STATE);
    setTaxonomyOptions(setDefaultTaxonomyOptions(taxonomies));
  }

  const validateSteps = (requiredSteps) => {
    return requiredSteps.every((step) => {
      return !!newMember[step] || !!taxonomyOptions[step];
    });
  }

  return (
    <NewEmployeeContext.Provider
      value={{
        ...newMember,
        taxonomies,
        taxonomyOptions,
        fetchData,
        createNewEmployee,
        handleUpdateForm,
        handleUpdateDate,
        handleUpdateTaxonomy,
        handleBulkUpdateForm,
        clearData,
        validateSteps,
      }}
    >
      {props.children}
    </NewEmployeeContext.Provider>
  )
}

const DEFAULT_STATE = {
  firstName: null,
  lastName: null,
  workEmail: null,
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  sex: null,
  ssn: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  zip: null,
  hireDateDay: null,
  hireDateMonth: null,
  hireDateYear: null,
  contributionOption: null,
  newHireWaitingPeriodOption: null,
  salary: '',
  salaryFrequency: null,
  jobTitle: null,
  weeklyHours: null,
  errors: {},
};

function setDefaultTaxonomyOptions(taxonomies) {
  const state = {};
  taxonomies.forEach(taxonomy => {
    state[taxonomy.uuid] = null;
  });

  return state;
}

NewEmployeeProvider.propTypes = {
  children: PropTypes.node,
  newMember: PropTypes.object,
  taxonomies: PropTypes.array,
  taxonomyOptions: PropTypes.object,
}
