import { snakeCase } from 'lodash'
import moment from 'moment'
import axios from 'axios'
import { stringify } from 'qs'

export const defaultConfig = {
  paramsSerializer: { serialize: params => dataToUrlParams(params) },
}

function parseUrlAndParams(urlString, existingParams, json) {
  const url = new URL(urlString, window.location.origin);
  const params = { ...existingParams };
  for (const [key, value] of url.searchParams) {
    params[key] = value;
  }

  // Create an array before deleting all fields to avoid modifying the object while iterating through it
  for (const key of Array.from(url.searchParams.keys())) {
    url.searchParams.delete(key);
  }

  url.pathname = json ? `${url.pathname}.json` : `${url.pathname}`

  return [url.toString(), params];
}

function request(urlString, method, data = {}, options) {
  const {
    params = {},
    callback,
    progressCallback = null,
    headers = {},
    json = true,
    // Only used in development
    htmlErrors = false,
    arrayFormat = null,
  } = options;

  const [parsedUrl, fullParams] = parseUrlAndParams(urlString, params, json);

  // we only want to set this in non smartview?
  // so pass in an override only in smartview
  if (ENV === 'development' && !htmlErrors) {
    headers['X-Requested-With'] = "XMLHttpRequest";
  }

  const config = { ...defaultConfig };
  if (arrayFormat) {
    config.paramsSerializer = { serialize: params => stringify(params, { arrayFormat, encode: false }) };
  }

  return axios({
    ...config,
    headers,
    params: fullParams,
    url: parsedUrl,
    method: method.toLowerCase(),
    data: json ? dataWithAuthToken(keysToUnderscored(data)) : data,
    onUploadProgress: progressCallback,
    onDownloadProgress: progressCallback,
  }).then(response => {
    const { data, status } = response;

    if (callback) {
      callback(data, status);
    } else {
      return Promise.resolve(data);
    }
  }).catch((error) => {
    // a response happened, but failed status validation
    if (error.response) {
      const { data, status } = error.response;

      if (ENV === 'development' && status === 500 && !htmlErrors) {
        console.log(data);
      }

      if (status === 401 && data.error && data.error.code === 'login_required') {
        // This will kick the user out to /login,
        // but not until saving the "attempted url"
        window.location.reload(true);
        // then return original rejection below

      } else if (callback) {
        // Pass all error statuses into the callback, but
        // rely on the callback throwing an error to make it
        // to the unhandled rejection redirect below
        callback(data, status);
        return;
      }
    }

    return Promise.reject(error);
  });
}

// Send a request using a FormData object instead of a json body,
// so we can upload files
export function formRequest(method, url, formData, callback, progressCallback) {
  const supportedMethods = ['PUT', 'POST', 'DEL'];
  if (supportedMethods.indexOf(method) === -1) {
    throw "Unsupported method for form request";
  }

  // Use regular form data instead of json data,
  // but we want regular json for the response
  // to avoid html redirect on session timeout
  // and any other before_actions that can
  // default to html output
  const options = {
    callback, progressCallback,
    json: false,
    headers: {
      'X-CSRF-Token': FORM_TOKEN,
      'Accept': 'application/json',
    },
  }

  return request(url, method, formData, options)
}

function dataWithAuthToken(data = {}) {
  return { ...data, authenticity_token: FORM_TOKEN }
}

export function get(url, data = {}, callback, options = {}) {
  return request(url, 'get', {}, { ...options, params: data, callback });
}

export function del(url, data = {}, callback) {
  return request(url, 'delete', data, { callback });
}

export function post(url, data = {}, callback) {
  return request(url, 'post', data, { callback });
}

export function put(url, data = {}, callback) {
  return request(url, 'put', data, { callback });
}

const ajax = { request, get, del, post, put, formRequest, isUuid, defaultConfig };
export default ajax;


const SESSION_TIMEOUT = 1200000; // 20 min
if (process.env.NODE_ENV === 'production') {
  setTimeout(checkTimeout, SESSION_TIMEOUT + 1000)
}

function checkTimeout() {
  get('/session', {}, (res) => {
    if (res.session && res.session.loggedIn) {
      // convert from seconds to milliseconds
      const timeoutTimestamp = (res.session.sessionTimeout + 1) * 1000;
      const timeout = timeoutTimestamp - moment().valueOf()
      setTimeout(checkTimeout, timeout)
    } else {
      // This will kick the user out to /login,
      // but not until saving the "attempted url"
      window.onbeforeunload = null;
      window.location.reload()
    }
  })
}

function dataToUrlParams(dataObject) {
  const data = keysToUnderscored(dataObject)
  let params = '';
  Object.keys(data).forEach(key => {
    const value = data[key];
    if (Array.isArray(value)) {
      params += `${stringify({ [key]: value }, { arrayFormat: 'brackets', encode: false })}&`
    } else if (isObject(value)) {
      // NOTE: does not work with nested objects! (yet!)
      params += objectToUrlParams(key, value)
    } else {
      params += `${stringify({ [key]: value })}&`;
    }
  })
  return params.slice(0, -1);
}

function objectToUrlParams(key, value) {
  let params = '';
  Object.keys(value).forEach(k => {
    const thisVal = value[k];
    params += `${key}[${k}]=${thisVal}&`;
  })
  return params;
}

export function keysToUnderscored(item) {
  if (Array.isArray(item)) {
    return item.map(i => keysToUnderscored(i))
  } else if (isObject(item)) {
    return objectKeysToUnderscored(item)
  } else {
    return item;
  }
}

function objectKeysToUnderscored(object) {
  if (object.preserveKeys) {
    const newObject = Object.assign(object)
    delete object.preserveKeys;
    return newObject;
  } else {
    const newObject = {}
    Object.keys(object).forEach(key => {
      const newKey = isUuid(key) ? key : snakeCase(key);
      newObject[newKey] = keysToUnderscored(object[key])
    })
    return newObject;
  }
}

function isObject(item) {
  return item !== null && typeof item === 'object'
}

export function isUuid(str) {
  if (typeof str !== "string") {
    return false;
  }

  // https://apidock.com/ruby/SecureRandom/uuid/class
  // V4 as of 4/17/2020
  const v4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
  return str.match(v4);
}
