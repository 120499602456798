import './CompanySwitcher.scss';
import PropTypes from "prop-types";
import ChevronRight from 'icons/global/outline_16px/chevron_right_16px.svg';

const CompanySwitcher = ({
  currentCompany,
  adminCompanies,
  handleToggleCompanySwitcher
}) => {

  const hasMultipleCompanies = adminCompanies?.length > 1;

  const toggleCompanySwitcherModal = () => {
    if (hasMultipleCompanies) handleToggleCompanySwitcher();
  }

  const toggleCompanySwitcherModalOnKeyDown = (e) => {
    if (e.key !== "Enter" && e.key !== " ") return;

    toggleCompanySwitcherModal();
  }

  return (
    <div
      role={ hasMultipleCompanies ? 'button' : 'note' }
      className={`CompanySwitcher ${hasMultipleCompanies ? 'active' : ''}`}
      onClick={toggleCompanySwitcherModal}
      onKeyDown={toggleCompanySwitcherModalOnKeyDown}
      tabIndex={hasMultipleCompanies ? 0 : -1}
      data-testid="company-switcher"
    >
      <div className="CompanySwitcher__company-attributes">
        <div className="CompanySwitcher__company-name">
          {currentCompany?.shortName || currentCompany?.name  }
        </div>
        <div className="CompanySwitcher__group-number">
          { currentCompany?.groupNumber ? `Group ${currentCompany.groupNumber}` : '-' }
        </div>
      </div>
      { hasMultipleCompanies && <ChevronRight className="CompanySwitcher__chevron" /> }
    </div>
  )
};


CompanySwitcher.propTypes = {
  currentCompany: PropTypes.object,
  adminCompanies: PropTypes.array,
  handleToggleCompanySwitcher: PropTypes.func,
};


export default CompanySwitcher;
