import "./PublicLayout.scss"

import { Component, useLayoutEffect } from 'react';
import PropTypes            from 'prop-types'

import Logo from 'images/secondary_logo'
import FlashMessageQueue from 'FlashMessageQueue'


export default class PublicLayout extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired
  }

  render() {
    return (
      <div className="PublicLayout">
        <main className="page-content">
          <div className="logo-wrapper">
            <a href="/">
              <Logo className="logo" />
            </a>
          </div>
          <div className="content-wrapper">
            <FlashMessageQueue />
            {this.props.children}
          </div>
        </main>

        <footer className="page-footer">
          <span className="contact">
            Need help? Give us a call anytime:
            <span className="phone">
              (833) 726-2123
            </span>
          </span>

          <ul className="menu">
            <li>
              Sana Benefits &copy;
            </li>

            <li>
              <a href="https://sanabenefits.com/terms-of-service">Terms</a>
            </li>

            <li>
              <a href="https://sanabenefits.com/privacy-policy">Privacy</a>
            </li>
          </ul>
        </footer>
      </div>
    )
  }
}

export function useHideContactInfo() {
  useLayoutEffect(() => {
    document.querySelector('.PublicLayout').classList.add("PublicLayout--hide-contact-info");

    return () => {
      document.querySelector('.PublicLayout').classList.remove("PublicLayout--hide-contact-info");
    };
  }, [])
}
