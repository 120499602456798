import "./SelectInput.scss";

import { Component } from 'react';
import PropTypes from 'prop-types';
import { generate } from 'shortid';
import classnames from 'classnames';
import DownArrow from '../../lib/images/down-arrow.svg'

import ErrorMessage from 'ErrorMessage';
import ErrorMessageV2 from 'ErrorMessageV2';

/*
  NOTICE: This has been deprecated in favor of Dropdown.jsx
  mostly due to its inability to sort the options in an easy
  and consistent way (object ordering is not guaranteed)
*/

export default class SelectInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.object,
    optionOrder: PropTypes.array,
    nullPlaceholder: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    onChange: PropTypes.func,
    errors: PropTypes.object,
    errorKey: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string,
    autofocus: PropTypes.bool, // on every re-render
    onEnter: PropTypes.func,
    variant: PropTypes.oneOf([null, undefined, 'v2']),
    allowNull: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    disabled: false,
    nullPlaceholder: "",
    autofocus: false,
    onEnter: () => {},
    allowNull: false,
    variant: null,
  }

  state = {
    inputId: `select_input_${generate()}`
  }

  handleChange = event => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(event.target.value);
    }
  }

  optionKeys() {
    const { options, optionOrder } = this.props;
    return optionOrder ? optionOrder : Object.keys(options);
  }

  renderOptions() {
    const { options, nullPlaceholder, allowNull } = this.props;
    const optionElements = this.optionKeys().map(key => {
      if (!Object.keys(options).includes(key)) {
        // If this key is not included in the options hash.
        // (will only ever happen is props.optionOrder is defined)
        return
      } else {
        return (
          <option key={key} value={key}>{options[key]}</option>
        )
      }
    })

    optionElements.unshift(
      <option
        key={'placeholder'}
        value=""
        disabled={!allowNull}
      >
        {nullPlaceholder}
      </option>
    )

    return optionElements;
  }

  render() {
    const {
      label, name, value, className,
      errors, errorKey, disabled, dataTestId, autofocus, onEnter,
      variant
    } = this.props;

    const { inputId } = this.state;

    const classes = classnames(
      "SelectInput",
      className,
      {
        v2: (variant === 'v2'),
        disabled
      })

    const selectClassName = classnames({
      'placeholder': !value
    })

    return (
      <div className={classes}>
        <div className="select-content">
          {(() => {
            if (label) {
              return <label htmlFor={inputId}>{label}</label>;
            }
          })()}
          <div className="select-dropdown">
            <select
              className={selectClassName}
              name={name}
              id={inputId}
              value={value || ''}
              onChange={this.handleChange}
              disabled={disabled}
              data-testid={dataTestId}
              ref={input => autofocus && input && input.focus()}
              onKeyPress={e => e.key === 'Enter' && onEnter()}
            >
              {this.renderOptions()}
            </select>
            <DownArrow />
          </div>
        </div>
        {errors && (errorKey || name) &&
          <>
            {(variant === 'v2') ?
              <ErrorMessageV2 errors={errors[errorKey]} /> :
              <ErrorMessage errors={errors[errorKey]} />
            }
          </>
        }
      </div>
    )
  }
}
