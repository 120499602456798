import "./ErrorMessage.scss"

import { Component } from 'react';
import PropTypes            from 'prop-types'
import classnames           from 'classnames'

export default class ErrorMessage extends Component {
  static propTypes = {
    errors: PropTypes.array,
    className: PropTypes.string,
    standalone: PropTypes.bool,
  }

  static defaultProps = {
    errors: [],
    className: '',
    standalone: false,
  }

  render() {
    const { errors, standalone, className } = this.props;
    if (errors === null || errors === undefined || errors.length === 0) {
      return null;
    }

    return (
      <ul className={classnames("ErrorMessage", { standalone }, className)} data-testid="error-message">
        {errors.map((error, i) => {
          return (
            <li key={`{$error}-${i}`}>{error}</li>
          )
        })}
      </ul>
    )
  }
}
