import './UnsubscribeView.scss'

import PropTypes from 'prop-types';

export default function UnsubscribeView(props) {
  const { success } = props;
  const title = success ? 'You have been unsubscribed from our email list.' : 'We were unable to automatically unsubscribe you from our email list.';
  const body = success ? 'You will still receive emails that contain necessary information regarding your plan or account activity.' : 'Please email hello@sanabenefits.com or call (833) 726-2123 for assistance.';
  return (
    <div className="UnsubscribeView">
      <h1>{title}</h1>
      <span className="description">{body}</span>
    </div>
  )
}

UnsubscribeView.propTypes = {
  success: PropTypes.bool,
};
