import { createContext, Component } from 'react';
import PropTypes            from 'prop-types';

export const contextName = 'CurrentUserContext';
export const CurrentUserContext = createContext();

export default class CurrentUserProvider extends Component {
  state = {
    currentUser: INITIAL_DATA.currentUser || {},
    offerings: INITIAL_DATA.navOfferings || [],
  }

  isMember = () => {
    const { personType } = this.state.currentUser;

    return personType === 'Member';
  }

  render() {
    const { currentUser, offerings } = this.state;
    const email = currentUser ? currentUser.email : '';
    const personType = currentUser ? currentUser.personType : '';
    const fullNameOrEmail = currentUser ? currentUser.fullNameOrEmail : '';
    const referralCode = currentUser ? currentUser.referralCode : null;
    const referralRockMemberId = currentUser ? currentUser.referralRockMemberId : null;
    const firstName = currentUser ? currentUser.firstName : '';
    return (
      <CurrentUserContext.Provider
        value={{
          email,
          personType,
          fullNameOrEmail,
          referralCode,
          referralRockMemberId,
          isMember: personType === 'Member',
          isDependent: personType === 'Dependent',
          offerings,
          firstName,
        }}
      >
        {this.props.children}
      </CurrentUserContext.Provider>
    )
  }
}

CurrentUserProvider.propTypes = {
  children: PropTypes.node,
}
