import "./InputV2.scss";

import { forwardRef } from "react";
import PropTypes            from "prop-types";
import classnames           from "classnames";
import ErrorMessage         from "../ErrorMessageV2/ErrorMessageV2";
import { generate }         from 'shortid';

const Input = forwardRef((props, ref) => {
  const {
    name,
    disabled,
    value,
    label,
    icon,
    min,
    max,
    type = 'text',
    helperText,
    errors = [],
    placeholder = " ",
    className,
    dataTestId,
    onKeyDown,
  } = props;

  const hasHelperText = (!errors || errors.length === 0) && props.helperText;
  const hasErrors = errors && errors.length > 0;
  const inputClassName = classnames({
    "input--helperText": hasHelperText,
  })

  const inputId = props.inputId || generate();

  return (
    <div className={classnames('input', className)}>
      {label && <label className="input--label" htmlFor={inputId}>{label}</label>}
      <div className="input__container">
        <input
          // TODO: Use the SCSS red_400 variable color
          id={inputId}
          style={hasErrors ? { borderColor: "#B6412F" } : null}
          disabled={disabled}
          className={inputClassName}
          ref={ref}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={e => props.onChange && props.onChange(e.target.value) }
          max={max}
          min={min}
          data-testid={dataTestId}
          onKeyDown={onKeyDown}
        />
        <i className="input__icon">{icon}</i>
      </div>
      {hasErrors && <ErrorMessage errors={errors} />}
      {hasHelperText && <span className="input--helperText">{helperText}</span>}
    </div>
  )
})

Input.displayName = "Input";

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  helperText: PropTypes.string,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  max: PropTypes.any,
  min: PropTypes.any,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
}

export default Input;
