import "./ErrorMessageV2.scss"

import PropTypes            from "prop-types"

export default function ErrorMessage(props) {
  ErrorMessage.propTypes = {
    errors: PropTypes.array,
  }

  ErrorMessage.defaultProps = {
    errors: [],
  }

  const { errors } = props;
  if (errors === null || errors === undefined || errors.length === 0) {
    return null;
  }

  return (
    <ul className={"ErrorMessageV2"} data-testid="error-message">
      {errors.map((error, i) => {
        return (
          <li key={`{$error}-${i}`}>{error}</li>
        )
      })}
    </ul>
  )
}
