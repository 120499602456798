import './RegisterVerification.scss'

import { Component } from 'react';
import PropTypes            from 'prop-types'

import DateInput            from 'DateInput'
import FormattingInput      from 'FormattingInput'
import Button               from 'Button'

export default class RegisterVerification extends Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    birthDay: PropTypes.string,
    birthMonth: PropTypes.string,
    birthYear: PropTypes.string,
    ssn: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    updateDate: PropTypes.func.isRequired,
    errors: PropTypes.object
  }

  static defaultProps = {
    ssn: '',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
  }

  isContinueButtonDisabled = () => {
    const { birthDay, birthMonth, birthYear, ssn } = this.props;
    return !birthDay || !birthMonth || !birthYear || ssn.length !== 11;
  }

  render() {
    const { handleSubmit, updateForm, updateDate, birthDay, birthMonth, birthYear, firstName, ssn, errors } = this.props;
    return (
      <div className="RegisterVerification" data-testid="register-verification">
        <form>
          <h1>Hi, {firstName}!</h1>

          <p className="instructions">
            Please verify your information.
          </p>

          <DateInput
            label="Birth"
            errors={errors}
            errorKey="dateOfBirth"
            onChange={(part, val) => updateDate('birth', part, val)}
            day={birthDay}
            month={birthMonth}
            year={birthYear}
            className="birth-date-input"
          />
          <FormattingInput
            type="ssn"
            value={ssn}
            label="Social Security Number"
            errors={errors}
            errorKey={'ssn'}
            required={true}
            onChange={val => updateForm('ssn', val)}
            showForgotPasswordLink={false}
            autocomplete={false}
          />
          { errors &&
            <span className="registration-error">{errors.does_not_match[0]}</span>
          }
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={this.isContinueButtonDisabled()}
            dataTestId="continue-to-registration-button"
          >
            Continue to Registration
          </Button>
        </form>
      </div>
    )
  }
}
