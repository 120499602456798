import { useState, useEffect, useRef }  from 'react';

export function useContainerWidth() {
  const [width, setWidth] = useState(null);
  const containerEl = useRef(null);
  const handleResize = () => {
    setWidth(containerEl.current.offsetWidth);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return [width, containerEl];
}

export function useCheckboxChecked(checked = false) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  }

  return [isChecked, handleCheckboxClick];
}

export function useSelectedIndex(defaultVal = null) {
  const [selectedIndex, setSelectedIndex] = useState(defaultVal);

  const onSelect = (index) => {
    setSelectedIndex(index);
  }

  const onClear = () => {
    setSelectedIndex(null);
  }

  return [selectedIndex, onSelect, onClear];
}

export function useHover() {
  const [isHovering, setIsHovering] = useState(false);
  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);

  return [isHovering, onMouseEnter, onMouseLeave];
}

export function useLoading(defaultVal = true) {
  const [loading, setLoading] = useState(defaultVal);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  return [loading, startLoading, stopLoading];
}

export function useShow(defaultVal = false) {
  const [show, setShow] = useState(defaultVal);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const toggle = () => setShow(prevShow => !prevShow);

  return [show, toggle, onShow, onHide];
}

export function useTextInput() {
  const [value, setValue] = useState('');
  const onChange = val => setValue(val);
  const onClear = () => setValue('');

  return [value, onChange, onClear];
}

export function useTabs(defaultVal = '') {
  const [tab, setTab] = useState(defaultVal);
  const onChange = val => setTab(val);

  return [tab, onChange];
}

// skips initial render
export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
}

export function useDragging(dragContainerEl) {
  const [dragHappening, setDragHappening] = useState(false);

  const highlightDragArea = () => setDragHappening(true);
  const unhighlightDragArea = () => setDragHappening(false);

  useEffect(() => {
    const currentRef = dragContainerEl.current
    currentRef.addEventListener('dragover', highlightDragArea);
    currentRef.addEventListener('dragenter', highlightDragArea);
    currentRef.addEventListener('dragleave', unhighlightDragArea);
    currentRef.addEventListener('dragend', unhighlightDragArea);
    currentRef.addEventListener('drop', unhighlightDragArea);

    return () => {
      currentRef.removeEventListener('dragover', highlightDragArea);
      currentRef.removeEventListener('dragenter', highlightDragArea);
      currentRef.removeEventListener('dragleave', unhighlightDragArea);
      currentRef.removeEventListener('dragend', unhighlightDragArea);
      currentRef.removeEventListener('drop', unhighlightDragArea);
    }
  }, []);

  return [dragHappening, dragContainerEl];
}

export function useOutsideClick(callback) {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
        event.stopPropagation();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

export function useTrueFalse(defaultVal = false) {
  const [isTrue, setIsTrue] = useState(defaultVal);
  const setTrue = () => {
    setIsTrue(true);
  }

  const setFalse = () => {
    setIsTrue(false);
  }

  return [isTrue, setTrue, setFalse];
}

/**
 * A hook to handle multiple state values and the update methods
 * for those key/value pairs. Should help prevent duplicate code
 * since this is a very common state management pattern we use in
 * our codebase.
 *
 * @param {Hash} defaultVal - default state for forms or etc.
 * @param {function} callback - callback to be called after state update
 * @returns {Array} - updated hash, both update methods
 */
export function useSelections(defaultVal = {}, callback) {
  const [selections, setSelections] = useState(defaultVal);

  const updateSelections = (key, value) => {
    setSelections(Object.assign({}, selections, { [key]: value }));
    if (callback) callback(key);
  }

  const updateAllSelections = (newSelections) => {
    setSelections(newSelections);
    if (callback) callback();
  }

  return [selections, updateSelections, updateAllSelections];
}
