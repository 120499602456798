import "./DateInput.scss"

import { Component, useState } from 'react';
import PropTypes            from 'prop-types'
import classnames           from 'classnames'
import moment               from 'moment'
import ErrorMessage         from 'ErrorMessageV2'

import SelectInput from 'SelectInput'

export default class DateInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    errors: PropTypes.object,
    errorKey: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    monthOnly: PropTypes.bool,
    hideYear: PropTypes.bool,
    yearsToGoBack: PropTypes.number,
    yearsToGoForward: PropTypes.number,
    optional: PropTypes.bool,
    disabled: PropTypes.bool,
    monthDisabled: PropTypes.bool,
    shortMonthNames: PropTypes.bool,
    textForDays: PropTypes.object,
    enableV2: PropTypes.bool,
  }

  static defaultProps = {
    monthOnly: false,
    yearsToGoBack: 100,
    yearsToGoForward: 0,
    optional: false,
    disabled: false,
    monthDisabled: false,
    textForDays: {},
    enableV2: false,
  }

  render() {
    const {
      label,
      errors,
      errorKey,
      day,
      month,
      year,
      onChange,
      className,
      name,
      monthOnly,
      hideYear,
      yearsToGoBack,
      yearsToGoForward,
      optional,
      disabled,
      monthDisabled,
      shortMonthNames,
      textForDays,
      enableV2,
    } = this.props;

    const dayValue = monthOnly ? '1' : day;
    const yearSelectOptions = yearOptions(yearsToGoBack, yearsToGoForward);
    const nullPlaceholder = optional ? 'Optional' : '';
    const v2ClassNames = classnames({
      "DateInput--errors": enableV2 && hasV2Errors(errors, errorKey),
    });

    return (
      <div className={classnames("DateInput", v2ClassNames, className, { 'month-only': monthOnly })}>
        <SelectInput
          className="month"
          value={month}
          options={ shortMonthNames ? DOB_MONTH_SHORT_OPTIONS : DOB_MONTH_OPTIONS }
          optionOrder={DOB_MONTH_ORDER}
          label={[label, "Month"].join(' ')}
          errors={enableV2 ? {} : errors}
          errorKey={enableV2 ? '' : errorKey}
          required={true}
          onChange={val => onChange('month', val)}
          nullPlaceholder={nullPlaceholder}
          disabled={disabled || monthDisabled}
          variant={enableV2 ? 'v2' : null}
        />
        {!monthOnly && (
          <SelectInput
            className="day"
            value={day}
            options={dayOptionsFor(month, year, textForDays)}
            optionOrder={DOB_DAY_ORDER}
            label="Day"
            required={true}
            onChange={val => onChange('day', val)}
            nullPlaceholder={nullPlaceholder}
            disabled={disabled}
            variant={enableV2 ? 'v2' : null}
          />
        )}
        {!hideYear && (
          <SelectInput
            className="year"
            value={year}
            options={yearSelectOptions}
            optionOrder={Object.keys(yearSelectOptions).reverse()}
            label="Year"
            required={true}
            onChange={val => onChange('year', val)}
            nullPlaceholder={nullPlaceholder}
            disabled={disabled}
            variant={enableV2 ? 'v2' : null}
          />
        )}
        {name && (
          <input type="hidden" name={name} value={`${year}-${month}-${dayValue}`} />
        )}
        {errors && enableV2 && (<ErrorMessage errors={errors[errorKey]} />)}
      </div>
    )
  }
}


/* =============================================================================
   Helper methods / constants for this component
============================================================================= */

const DOB_MONTH_OPTIONS = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December'
}

const DOB_MONTH_SHORT_OPTIONS = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};

const DOB_MONTH_ORDER = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
]

function dayOptionsFor(month, year, textForDays = {}) {
  const options = {}
  if (month === null || month === undefined || month === "") {
    return options;
  }
  for (let i = 1; i < 29; i++) {
    const val = String(i)
    if (i < 10) {
      options[`0${val}`] = val;
    } else {
      options[val] = val;
    }
  }
  if (month !== '02') {
    options["29"] = "29";
    options["30"] = "30";
  }

  if (month === '02' && moment([year]).isLeapYear()) {
    options["29"] = "29";
  }

  if (['01', '03', '05', '07', '08', '10', '12'].includes(month)) {
    options["31"] = "31";
  }

  if (Object.keys(textForDays).length > 0) {
    for (const [key, value] of Object.entries(textForDays)) {
      options[key.toString()] += ` ${value}`;
    }
  }

  return options;
}

const DOB_DAY_ORDER = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13',
  '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26',
  '27', '28', '29', '30', '31'
]

function yearOptions(yearsToGoBack, yearsToGoForward) {
  const options = {}
  const currentYear = (new Date()).getFullYear();
  const startYear = currentYear + yearsToGoForward;
  const endYear = currentYear - yearsToGoBack
  for (let i = startYear; i >= endYear; i--) {
    const val = String(i)
    options[val] = val;
  }
  return options;
}

function hasV2Errors(errors, errorKey) {
  if (!errors) return false;

  if (errorKey) {
    if (!errors[errorKey]) return false;
    return Object.values(errors[errorKey]).length > 0;
  }

  return Object.values(errors).length > 0;
}

export function useDateInputState(initDateOrFun) {
  const [date, setDate] = useState(() => {
    let date = typeof initDateOrFun === 'function' ?
      initDateOrFun() :
      initDateOrFun;

    if (date == null) {
      return {};
    }

    date = moment(date);

    return {
      day: date.format("DD"),
      month: date.format("MM"),
      year: date.format("Y"),
    }
  });

  function onChange(part, value) {
    setDate((previousDate) => ({
      ...previousDate,
      [part]: value,
    }));
  }

  return [date, onChange];
}
