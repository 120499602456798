import { createContext, Component } from 'react';
import PropTypes            from 'prop-types';
import { DOC_STEPS }        from '../bundles/InternalApp/constants';

export const contextName = 'DocCenterContext';
export const DocCenterContext = createContext();

export default class DocCenterProvider extends Component {
  state = {
    filePath: null,
    fileName: null,
    displayName: null,
    currentStep: 0,
    companies: [],
    members: [],
    selectedCompany: null,
    selectedMemberGroup: null,
    memberId: '',
    memberName: '',
    category: null,
    sendEmail: false,
    uploadedDocLocation: null,
    isUploading: false,
    uploadErrors: null,
    uploadingUser: INITIAL_DATA.currentUser ? INITIAL_DATA.currentUser.email : null,
  }

  updateFile = (filePath) => {
    const fileName = filePath.split('\\').pop();
    this.setState({
      filePath,
      fileName,
      displayName: fileName,
    });
  }

  updateDisplayName = (displayName) => {
    this.setState({ displayName });
  }

  updateCompanies = (companies) => {
    this.setState({ companies });
  }

  updateMembers = (members) => {
    this.setState({ members })
  }

  updateAccess = (selectedCompany, selectedMember) => {
    this.setState({ selectedCompany, selectedMember })
  }

  updateSelectedCompany = (selectedCompany) => {
    this.setState({ selectedCompany });
  }

  updateSelectedMemberGroup = (selectedMemberGroup) => {
    this.setState({ selectedMemberGroup });
  }

  updateMemberId = (value) => {
    this.setState({ memberId: value });
  }

  updateCategory = (category) => {
    this.setState({ category });
  }

  updateMemberName = (memberName) => {
    this.setState({ memberName });
  }

  updateCurrentStep = (currentStep) => {
    this.setState({ currentStep });
  }

  updateUploadedDocLocation = (uploadedDocLocation) => {
    this.setState({ uploadedDocLocation });
  }

  toggleIsUploading = () => {
    this.setState({ isUploading: !this.state.isUploading });
  }

  toggleSendEmail = () => {
    this.setState({ sendEmail: !this.state.sendEmail });
  }

  handleSuccess = () => {
    this.setState({
      currentStep: DOC_STEPS.RESULT,
      uploadErrors: null,
    });
  }

  handleError = (errors) => {
    this.setState({
      currentStep: DOC_STEPS.RESULT,
      uploadErrors: errors,
    });
  }

  handleNext = () => {
    if (this.state.currentStep === DOC_STEPS.RESULT) return;
    this.setState({
      currentStep: this.state.currentStep + 1,
    });
  }

  handleBack = () => {
    if (this.state.currentStep === DOC_STEPS.BROWSE) return;
    this.setState({
      currentStep: this.state.currentStep - 1,
    });
  }

  clean = () => {
    this.setState({
      filePath: null,
      fileName: null,
      companies: [],
      displayName: null,
      currentStep: DOC_STEPS.BROWSE,
      selectedCompany: null,
      selectedMemberGroup: null,
      category: null,
      sendEmail: false,
      memberId: '',
      memberName: '',
    });
  }

  get payload() {
    const { selectedMemberGroup, memberId } = this.state;
    if (!selectedMemberGroup) return null;

    let member = selectedMemberGroup;
    let memberType = null;
    if (selectedMemberGroup === 'one-member') {
      member = memberId
      memberType = 'member';
    } else if (selectedMemberGroup.indexOf('all') === -1) {
      memberType = 'plan';
    }

    return {
      member,
      memberType,
      displayName: this.state.displayName,
      fileName: this.state.fileName,
      company: this.state.selectedCompany,
      category: this.state.category,
      sendEmail: this.state.sendEmail,
      uploadingUser: this.state.uploadingUser,
    }
  }

  render() {
    return (
      <DocCenterContext.Provider
        value={{
          ...this.state,
          updateFile: this.updateFile,
          updateDisplayName: this.updateDisplayName,
          updateCompanies: this.updateCompanies,
          updateMembers: this.updateMembers,
          updateAccess: this.updateAccess,
          updateSelectedCompany: this.updateSelectedCompany,
          updateSelectedMemberGroup: this.updateSelectedMemberGroup,
          updateMemberId: this.updateMemberId,
          updateCategory: this.updateCategory,
          updateMemberName: this.updateMemberName,
          updateCurrentStep: this.updateCurrentStep,
          updateUploadedDocLocation: this.updateUploadedDocLocation,
          toggleIsUploading: this.toggleIsUploading,
          toggleSendEmail: this.toggleSendEmail,
          handleSuccess: this.handleSuccess,
          handleError: this.handleError,
          handleNext: this.handleNext,
          handleBack: this.handleBack,
          clean: this.clean,
          payload: this.payload,
        }}
      >
        {this.props.children}
      </DocCenterContext.Provider>
    )
  }
}

DocCenterProvider.propTypes = {
  children: PropTypes.node,
}
