import './RegisterLink.scss'

import { Component } from 'react';
import PropTypes            from 'prop-types'

import Input        from 'Input'
import Button       from 'Button'

export default class RegisterLink extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    currentStep: PropTypes.string.isRequired,
    updateForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object
  }

  render() {
    const { email, currentStep, updateForm, handleSubmit, errors } = this.props;
    if (currentStep === 'link') {
      return (
        <div className="RegisterLink" data-testid="register-link">
          <form>
            <h1>Register your account</h1>
            <p className="instructions">
              Enter your work email address below and we'll email you a link to register your account.
            </p>
            <Input
              type="text"
              name="email"
              label="Work Email Address"
              errors={errors}
              value={email}
              onChange={val => updateForm('email', val)}
            />
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={!email}
              dataTestId="send-activation-button"
            >
              Send Activation Link
            </Button>
          </form>
          <a href="/" className="cancel">Back to Log In</a>
        </div>
      )
    } else if (currentStep === 'alreadyRegistered') {
      return (
        <div
          className="RegisterLink"
          data-testid="register-link-already-registered"
        >
          <h1>Looks like you're already registered</h1>
          <p className="instructions confirmation">
            <Button type="a" href={`/login`}>
              Log In
            </Button>
            <br />
            <br />
            If you think this is a mistake, please call us at (833) 726-2123.
          </p>
        </div>
      );
    } else {
      return (
        <div className="RegisterLink" data-testid="register-link-sent">
          <h1>Your registration request has been sent.</h1>
          <p className="instructions confirmation">
            Check your email for an activation link to register your account.
          </p>
        </div>
      )
    }

  }

}
