export const DOC_STEPS = {
  BROWSE: 0,
  DISPLAY_NAME: 1,
  ACCESS: 2,
  CATEGORY: 3,
  REVIEW: 4,
  RESULT: 5,
}

export const CATEGORIES_MAP = {
  'plan_information': {
    formatted: 'Plan Information',
    type: 'all',
  },
  'pharmacy': {
    formatted: 'Pharmacy',
    type: 'all',
  },
  'company_documents': {
    formatted: 'Company Documents',
    type: 'admin',
  },
  'rights_and_privacy': {
    formatted: 'Rights & Privacy',
    type: 'member',
  },
  'appeals_and_complaints': {
    formatted: 'Appeals & Complaints',
    type: 'member',
  },
  'renewals': {
    formatted: 'Renewals',
    type: 'admin',
  },
  'other': {
    formatted: 'Other',
    type: 'all',
  },
}
