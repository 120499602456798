import { createContext, Component } from 'react';
import PropTypes            from 'prop-types';

export const contextName = 'FeatureFlagContext';
export const FeatureFlagContext = createContext();

export default class FeatureFlagProvider extends Component {
  state = {
    featureFlags: INITIAL_DATA.featureFlags || {},
  }

  isFlagEnabled = (key) => {
    const { featureFlags } = this.state;

    return key in featureFlags && featureFlags[key];
  }

  render() {
    return (
      <FeatureFlagContext.Provider
        value={{ isFlagEnabled: this.isFlagEnabled }}
      >
        {this.props.children}
      </FeatureFlagContext.Provider>
    )
  }
}

FeatureFlagProvider.propTypes = {
  children: PropTypes.node,
}
