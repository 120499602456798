import './Modal.scss'

import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import CloseIcon  from './icons/close.svg';
import classNames from 'classnames';

const Modal = (props) => {
  const {
    children,
    className,
    onDismiss,
    closeOnClickAway = true,
    heading = " ",
    subheading,
    footer,
    showClose = true,
    size = 'lg',
    visible,
    hideScrollbar = true,
  } = props;

  const modalContainer = useRef(null);
  const bodyRef = useRef();

  useEffect(() => {
    window.addEventListener("keydown", listenForEsc, true);

    if (hideScrollbar) {
      document.body.style.overflow = visible ? "hidden" : "";
    }

    return () => {
      window.removeEventListener("keydown", listenForEsc, true);
      document.body.style.overflow = "";
    }
  });

  const findFirstFocusableElement = container => {
    return Array.from(container.getElementsByTagName("*")).find(isFocusable);
  };

  const isFocusable = item => {
    if (item.tabIndex < 0) {
      return false;
    }
    switch (item.tagName) {
      case "A":
        return !!item.href;
      case "INPUT":
        return item.type !== "hidden" && !item.disabled;
      case "SELECT":
      case "TEXTAREA":
      case "BUTTON":
        return !item.disabled;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (visible && bodyRef.current) {
      const firstFocusableInput = findFirstFocusableElement(bodyRef.current);
      firstFocusableInput && firstFocusableInput.focus();
    }
  }, [visible]);

  const listenForEsc = event => {
    if (visible && onDismiss) {
      if (event.key === "Escape" || event.keyCode === 27) {
        onDismiss();
      }
    }
  }

  const sizeClass = size === 'sm' ? 'sm' : 'lg';
  const classes = classNames('modal-v2', className, {visible})

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss();
    }
  }

  const handleClickOutside = (event) => {
    event.persist();

    if (modalContainer.current && modalContainer.current.contains(event.target)) {
      return;
    }

    if (closeOnClickAway) {
      handleDismiss();
    }
  }

  return (
    <div
      onClick={handleClickOutside}
      onKeyUp={listenForEsc}
      className={classes}
      data-testid="modal-v2"
      role="presentation"
    >
      <div
        role="dialog"
        className={classNames('modal-container', sizeClass)}
        ref={modalContainer}
      >
        <header className="heading">
          {showClose && <div className="close-container">
            <button className="close-button" onClick={handleDismiss} title="Close (ESC)">
              <CloseIcon />
            </button>
          </div>}

          {heading &&
            <h2 className="title">{heading}</h2>
          }
        </header>


        <div className="body" ref={bodyRef} >
          {subheading &&
            <p className="subheading">{subheading}</p>
          }
          {children}
        </div>

        {footer &&
          <div className="footer">
            {footer}
          </div>
        }
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onDismiss: PropTypes.func,
  closeOnClickAway: PropTypes.bool,
  heading: PropTypes.node,
  subheading: PropTypes.node,
  footer: PropTypes.node,
  showClose: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  visible: PropTypes.bool,
  hideScrollbar: PropTypes.bool,
};

export default Modal;
