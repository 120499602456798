import "./Navigation.scss";

import { useState } from "react";
import PropTypes from "prop-types";
import SanaLogo from "./icons/primary_logo_black.svg";
import CancelIcon from "./icons/cancel.svg";
import DashboardIcon from "./icons/dashboard.svg";
import SanaPrimaryCareIcon from "./icons/sana-primary-care.svg";
import FindCareIcon from "./icons/find-care.svg";
import EmployeesIcon from "./icons/employees.svg";
import BenefitPlansIcon from "./icons/benefit-plans.svg";
import StopLossIcon from "./icons/stop-loss.svg";
import BillingHistoryIcon from "./icons/billing-history.svg";
import PermissionsIcon from "./icons/permissions.svg";
import MedicalIcon from "./icons/medical.svg";
import CarePartnersIcon from "./icons/care-partners.svg";
import DentalIcon from "./icons/dental.svg";
import VisionIcon from "./icons/vision.svg";
import OtherIcon from "./icons/other.svg";
import MedicalClaimsIcon from "./icons/medical-claims.svg";
import SubmitClaimIcon from "./icons/submit-a-claim.svg";
import HelpCenterIcon from "./icons/help-center.svg";
import DocumentsIcon from "./icons/documents.svg";
import NavMenu from "../NavMenu";
import classNames from "classnames"
import CompanySwitcher from "./components/CompanySwitcher";
import CompanySwitcherModal from "./components/CompanySwitcherModal";

export default function Navigation({
  bundleName,
  currentRoute,
  handleToggleNavigationVisible,
  isAdmin,
  isBroker,
  visible,
  offerings,
  currentCompany,
  adminCompanies,
  permissions,
}) {

  const [showCompanySwitcher, setShowCompanySwitcher] = useState(false);

  const renderBrokerLinks = () => {
    return (
      <div className="Navigation_link-group">
        {navLink({ text: "My Groups", route: "/broker/dashboard", Icon: DashboardIcon })}
      </div>
    );
  };

  const navLink = ({text, route, Icon, target, rel}) => {
    const isActive = currentRoute === route;

    return (
      <a
        href={route}
        target={target || "_self"}
        {...(rel ? { rel } : {})}
        onClick={(e) => e.currentTarget.blur()}
        className={classNames("Navigation_link", {"Navigation_link--active": isActive})}
        data-testid={route.split("/").pop()}>
        <Icon className="Navigation_icon" />
        {text}
      </a>
    )
  };

  const renderCompanyAdminLinks = () => {
    const hasEditAdminPermission = permissions?.editAdminPermissions;

    return (
      <div className="Navigation_link-group">
        {currentCompany && (
          <CompanySwitcher
            currentCompany={currentCompany}
            adminCompanies={adminCompanies}
            handleToggleCompanySwitcher={() => setShowCompanySwitcher(true)}
          />
        )}
        {navLink({ text: "Admin Dashboard", route: "/company/dashboard", Icon: DashboardIcon })}
        {navLink({ text: "Employees", route: "/members", Icon: EmployeesIcon })}
        {navLink({ text: "Benefit Plans", route: "/insurance-plans/medical", Icon: BenefitPlansIcon })}
        {navLink({ text: "Stop-loss", route: "/reinsurance-policies", Icon: StopLossIcon })}
        {navLink({ text: "Billing History", route: "/invoices", Icon: BillingHistoryIcon })}
        {hasEditAdminPermission && navLink({ text: "Permissions", route: "/permissions", Icon: PermissionsIcon })}
      </div>
    );
  };

  const renderMemberLinks = () => {
    return (
      <div>
        <div className="Navigation_link-group">
          {navLink({text: "My Dashboard", route: "/dashboard", Icon: DashboardIcon})}
          { offerings.includes("sana_care_visible") && navLink({text: "My Sana Care", route: "/care", Icon: SanaPrimaryCareIcon}) }
          {navLink({text: "Find Care", route: "/find-a-doctor", Icon: FindCareIcon})}
        </div>

        <div className="Navigation_link-group">
          <span className="Navigation_link-group-header">Benefits</span>

          {navLink({text: "Medical", route: "/benefits/medical", Icon: MedicalIcon})}
          {navLink({text: "Care Partners", route: "/benefits/care-partners", Icon: CarePartnersIcon})}
          {offerings.includes("dental") && navLink({text: "Dental", route: "/benefits/dental", Icon: DentalIcon})}
          {offerings.includes("vision") && navLink({text: "Vision", route: "/benefits/vision", Icon: VisionIcon})}
          {offerings.includes("life_and_disability") && navLink({text: "Other", route: "/benefits/other-benefits", Icon: OtherIcon})}
        </div>

        <div className="Navigation_link-group">
          <span className="Navigation_link-group-header">Claims</span>

          {navLink({text: "Medical Claims", route: "/my-claims/list", Icon: MedicalClaimsIcon})}
          {navLink({text: "Submit a Claim", route: "/my-claims/submit", Icon: SubmitClaimIcon})}
        </div>
      </div>
    );
  };

  const renderNavigationLinks = () => {
    switch (bundleName) {
      case 'CompanyAdminApp':
        return renderCompanyAdminLinks();
      case 'BrokerApp':
        return renderBrokerLinks();
      default:
        return renderMemberLinks();
    }
  };

  const DOCUMENT_PATH_ROOT = {
    "MemberPortalApp": "/member",
    "CompanyAdminApp": "/company",
    "BrokerApp": "/broker",
  }

  return (
    <nav className={classNames("Navigation", {"Navigation_link--active": visible})} aria-label="Primary">
      <a
        href="/"
        className="Navigation_header-logo"
        data-testid="Navigation_header-logo"
        title="Sana Primary Logo"
      >
        <SanaLogo />
      </a>

      <a onClick={() => handleToggleNavigationVisible() } className="Navigation_header-cancel" data-testid="Navigation_header">
        Close <CancelIcon />
      </a>

      { (isAdmin || isBroker) && <NavMenu activeApp={bundleName} isAdmin={isAdmin} isBroker={isBroker} /> }

      { renderNavigationLinks() }

      <div className="Navigation_link-group">
        <span className="Navigation_link-group-header">Resources</span>
        { navLink({text: "Documents", route: `${DOCUMENT_PATH_ROOT[bundleName]}/documents`, Icon: DocumentsIcon}) }
        {
          navLink({
            text: "Help Center",
            route: "//sanabenefits.zendesk.com/hc/en-us",
            target: '_blank',
            rel: 'noreferrer',
            Icon: HelpCenterIcon,
          })
        }
      </div>

      <div className="Navigation_mobile-controls">
        <a href="/account-settings" className="Navigation_link">Settings & Profile</a>
        <a href="/logout" className="Navigation_link">Logout</a>
      </div>
      <CompanySwitcherModal companies={adminCompanies} visible={showCompanySwitcher} onClose={() => setShowCompanySwitcher(false)} />
    </nav>
  );
}

Navigation.defaultProps = {
  offerings: [],
}

Navigation.propTypes = {
  bundleName: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
  handleToggleNavigationVisible: PropTypes.func,
  isAdmin: PropTypes.bool,
  isBroker: PropTypes.bool,
  visible: PropTypes.bool,
  offerings: PropTypes.arrayOf(PropTypes.string),
  currentCompany: PropTypes.object,
  adminCompanies: PropTypes.arrayOf(PropTypes.object),
  permissions: PropTypes.object,
};
