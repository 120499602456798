import { Component } from 'react';
import SmartView            from './SmartView';

export default SmartView;

// Helper to wrap a view component in a SmartView
export function wrapComponent(ComponentToWrap) {
  return class WrappedComponent extends Component {
    render() {
      const url = this.props.match ? this.props.match.url : window.location.pathname;

      return (
        <SmartView key={url}>
          <ComponentToWrap {...this.props} />
        </SmartView>
      )
    }
  }
}
