import "./SideNav.scss";

import { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { includes } from "lodash";

import NavLink from "NavLink";
import HamburgerIcon from "./icons/hamburger";
import Close from "./icons/close";
import { MasterContext } from "master_context";

import CreditCardIcon from "./icons/credit_card";
import DashboardIcon from "./icons/dashboard";
import InsurancePlansIcon from "./icons/insurance_plans";
import MembersIcon from "./icons/members";
import ClaimsIcon from "./icons/my_claims";
import ReinsuranceIcon from "./icons/reinsurance";
import CompaniesIcon from "./icons/companies";
import SearchIcon from "./icons/search";
import PrecertIcon from "./icons/precert";
import RuleEngineIcon from "./icons/rule_engine";
import DocumentCenterIcon from "./icons/doc_center";
import PermissionsIcon from "./icons/lock";
import CycPlanFileIcon from "./icons/upload_file";
import CarrierIcon from "./icons/carrier";
import ProviderRequestsIcon from "./icons/provider_requests";
import BrokeragesIcon from "./icons/brokerages";

export default function SideNav(props) {
  const { ShowNavContext, FeatureFlagContext } = useContext(MasterContext);
  const {
    showMoreNav,
    handleShowMoreNavAfterDelay,
    handleHideMoreNavAfterDelay,
    handleToggleMoreNav,
    listenForHideMoreNav,
  } = ShowNavContext;

  useEffect(() => {
    window.addEventListener("keydown", listenForHideMoreNav, true);

    return () => {
      window.removeEventListener("keydown", listenForHideMoreNav, true);
    };
  }, []);

  const {
    bundleName,
    hasAdminMember,
    permissions,
    shortenedName,
  } = props;
  const [currentNavToShow, setCurrentNavToShow] = useState(bundleName);

  const NAV_SETTINGS = {
    MemberPortalApp: {
      Dashboard: "/dashboard",
      Benefits: "/benefits",
      Claims: "/my-claims",
      "Find a Doctor": "/find-a-doctor",
    },
    CompanyAdminApp: {
      Dashboard: "/company/dashboard",
      Employees: "/members",
      "Benefit Plans": "/insurance-plans",
      "Stop-loss": "/reinsurance-policies",
      "Billing History": "/invoices",
      Permissions: "/permissions",
    },
    InternalApp: {
      Search: "/internal/search",
      "Claims Queue": "/internal/claims",
      "Member Claim Submissions": "/internal/member-claim-submissions",
      Companies: "/internal/companies",
      Brokerages: "/internal/brokerages",
      "Internal Reports": "/internal/reports",
      "Generate Quote": "/internal/quotes/generate",
      "Claros Dashboard": "/internal/claros-dashboard",
      "Renewals Dashboard": "/internal/renewals",
      "Payments Dashboard": "/internal/payments-dashboard?status=billed,failed",
      "Stoploss Claims": "/internal/reinsurance-claims",
      Precertifications: "/internal/precertifications",
      "BC Rule Engine": "/internal/rules/bc",
      "Action Rule Engine": "/internal/rules/action?target_kind=claim",
      "Reprice Rule Engine": "/internal/rules/action?target_kind=claim_line_item",
      "Document Center": "/internal/document-center",
      "Claim Tags": "/internal/claim-tags",
      Treaties: "/internal/treaties",
      "CYC Plan Files Dashboard": "/internal/cyc-plan-files",
      Carriers: "/internal/carriers",
      "Provider Contracts": "/internal/contracts/1",
      "Provider Requests": "/internal/provider-request",
      "Provider Self Nominations": "/internal/provider-self-nominations",
      "Enrollment Files": "/internal/enrollment-files",
      'Find Use Case': '/internal/find-use-case',
      'Claims Fund Vendors': '/internal/claims-fund-vendors',
      'Claims Fund Charges': '/internal/claims-fund-charges',
    },
  };

  NAV_SETTINGS["OpenEnrollmentApp"] = NAV_SETTINGS["MemberPortalApp"];

  const navEnhancements = FeatureFlagContext.isFlagEnabled("internalEternalVisibleNav");
  const navExpanded = showMoreNav;

  const renderNavLinks = () => {
    const nav = NAV_SETTINGS[currentNavToShow];
    const isInternal = currentNavToShow === "InternalApp";
    return Object.keys(nav)
      .filter((key) => {
        const permMap = NAV_PERMISSIONS[currentNavToShow] || {};
        const permissionName = permMap[key];
        if (!permissionName) return true;

        return permissions[permissionName];
      })
      .filter((key) => {
        const flagName = (NAV_FEATURE_FLAGS[currentNavToShow] || {})[key];
        return !flagName || FeatureFlagContext.isFlagEnabled(flagName);
      })
      .map((key) => {
        const IconComponent = NAV_ICONS[key];
        const active = includes(window.location.pathname, nav[key]);
        return (
          <NavLink
            key={key}
            url={nav[key]}
            text={key}
            isInternal={isInternal}
            active={active}
            expanded={navExpanded || navEnhancements}
          >
            <IconComponent />
          </NavLink>
        );
      });
  };

  return (
    <div className="SideNav">
      <nav
        className={classnames("sidebar", { show: navExpanded })}
        onMouseEnter={handleShowMoreNavAfterDelay}
        onMouseLeave={handleHideMoreNavAfterDelay}
      >
        <div className="sidebar-inner">
          <div className="sidebar-open-header">
            {(() => {
              if (hasAdminMember()) {
                return (
                  <div className="nav-switch">
                    <button
                      onClick={() => setCurrentNavToShow("CompanyAdminApp")}
                      className={classnames("nav-switch-button", {
                        current: currentNavToShow === "CompanyAdminApp",
                      })}
                    >
                      Employer Tools
                    </button>
                    <button
                      onClick={() => setCurrentNavToShow("MemberPortalApp")}
                      className={classnames("nav-switch-button", {
                        current: currentNavToShow === "MemberPortalApp",
                      })}
                    >
                      My Account
                    </button>
                  </div>
                );
              } else {
                return <span className="user-name">Hi {shortenedName}!</span>;
              }
            })()}
            <button className="close-sidebar" onClick={handleToggleMoreNav}>
              <Close className="close-sidebar-icon" />
            </button>
            {(() => {
              if (hasAdminMember()) {
                return (
                  <span className="user-name multi-nav-user-name">
                    Hi {shortenedName}!
                  </span>
                );
              }
            })()}
          </div>
          <button
            className="nav-show-more"
            href="#"
            onClick={handleToggleMoreNav}
          >
            <HamburgerIcon className="icon" />
          </button>
          <div className="nav-link-list">{renderNavLinks()}</div>
          <div className="additional-links">
            <a
              href="https://sanabenefits.zendesk.com/hc/en-us"
              className="additional-link"
            >
              Help Center
            </a>
            <a href="/account-settings" className="additional-link">
              Settings & Profile
            </a>
            <a href="/logout" className="additional-link">
              Log Out
            </a>
          </div>
        </div>
      </nav>
      <span
        className={classnames("sidebar-border-cover-up", {
          shift: navExpanded,
        })}
      />
    </div>
  );
}

SideNav.propTypes = {
  bundleName: PropTypes.string,
  hasAdminMember: PropTypes.func,
  permissions: PropTypes.object,
  shortenedName: PropTypes.string,
};

const NAV_ICONS = {
  Benefits: InsurancePlansIcon,
  Claims: ClaimsIcon,
  "Stoploss Claims": ClaimsIcon,
  "Find a Doctor": SearchIcon,
  Dashboard: DashboardIcon,
  Employees: MembersIcon,
  "Benefit Plans": InsurancePlansIcon,
  "Stop-loss": ReinsuranceIcon,
  "Billing History": CreditCardIcon,
  Search: SearchIcon,
  "Claims Queue": ClaimsIcon,
  "Member Claim Submissions": ClaimsIcon,
  Companies: CompaniesIcon,
  Brokerages: BrokeragesIcon,
  "Internal Reports": DashboardIcon,
  "Payments Dashboard": CreditCardIcon,
  "Generate Quote": InsurancePlansIcon,
  "Claros Dashboard": DashboardIcon,
  "Renewals Dashboard": InsurancePlansIcon,
  Precertifications: PrecertIcon,
  "BC Rule Engine": RuleEngineIcon,
  "Action Rule Engine": RuleEngineIcon,
  "Reprice Rule Engine": RuleEngineIcon,
  "Document Center": DocumentCenterIcon,
  "Claim Tags": ClaimsIcon,
  Treaties: InsurancePlansIcon,
  Permissions: PermissionsIcon,
  "CYC Plan Files Dashboard": CycPlanFileIcon,
  Carriers: CarrierIcon,
  "Provider Search": SearchIcon,
  "Provider Contracts": ProviderRequestsIcon,
  "Provider Requests": ProviderRequestsIcon,
  "Provider Self Nominations": ProviderRequestsIcon,
  "Enrollment Files": InsurancePlansIcon,
  "Find Use Case": SearchIcon,
  'Claims Fund Vendors': CompaniesIcon,
  'Claims Fund Charges': CreditCardIcon,
};

const NAV_PERMISSIONS = {
  InternalApp: {
    "Claims Queue": "viewClaims",
    "Member Claim Submissions": "reviewMemberClaimSubmissions",
    "Payments Dashboard": "manageInvoicing",
    "Stoploss Claims": "viewStoplossClaims",
    "Generate Quote": "createPrelimQuotes",
    "Claros Dashboard": "createPrelimQuotes",
    "Claim Tags": "viewClaims",
    "BC Rule Engine": "manageRulesEngine",
    "Action Rule Engine": "manageRulesEngine",
    "Reprice Rule Engine": "manageRulesEngine",
    "Internal Reports": "viewAndDownloadReports",
    "Provider Search": "providerSearchCrud",
    "Provider Contracts": "manageProviders",
    "Provider Requests": "manageProviders",
    "Provider Self Nominations": "manageProviders",
    "CYC Plan Files Dashboard": "cycPlanFilesDashboard",
    "Partner Files": "viewSensitiveMemberInfo",
  },
  CompanyAdminApp: {
    Employees: "viewEmployees",
    "Billing History": "viewInvoices",
    Permissions: "editAdminPermissions",
    "Stop-loss": "viewPlanSpend",
  },
};

// same nav when enrolling

const NAV_FEATURE_FLAGS = {
  InternalApp: {
    "Find Use Case": "findUseCaseInternalTool",
    Brokerages: "adminBrokerDataModel",
  },
}
