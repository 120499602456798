import './LoginView.scss'

import { Component } from 'react';
import FlashStore           from 'flash_store'
import { post }             from 'ajax'

import Input        from 'Input'
import Button       from 'Button'
import Tooltip      from 'Tooltip'
import InfoIcon     from 'images/info.svg'

export default class LoginView extends Component {
  state = {
    redirectTo: INITIAL_DATA.session.redirectTo,
    email: INITIAL_DATA.session.email,
    errors: INITIAL_DATA.errors,
    timeout: INITIAL_DATA.session.timeout,
    password: ''
  }

  updateForm = (key, value) => {
    const newCreds = {}
    newCreds[key] = value;
    this.setState(newCreds)
  }

  componentDidMount() {
    const { email, timeout } = this.state;

    // auto-focus
    if (email) {
      this.passwordInput.input.focus()
    } else {
      this.emailInput.input.focus()
    }

    if (timeout) {
      FlashStore.add('notice', 'You have been automatically logged out... for security! 👮', this.dismissTimeout)
    }
  }

  dismissTimeout() {
    post('/session/dismiss-timeout')
  }

  render() {
    const { email, password, errors, redirectTo } = this.state;

    const action = redirectTo ? `/login?redirect_to=${redirectTo}` : '/login'
    return (
      <div className="LoginView">
        <h1>Member Log In</h1>
        <form className="login-form" method="POST" action={action}>
          <div className="info-tooltip">
            <Tooltip
              small
              message="Not sure which email address to enter? We recommended that you use a personal email when you registered."
            >
              <InfoIcon />
            </Tooltip>
          </div>
          <Input
            type="email"
            name="email"
            label="Email Address"
            required={true}
            value={email}
            onChange={val => this.updateForm('email', val)}
            ref={input => this.emailInput = input}
            tabIndex={1}
            className="email-input"
          />
          <Input
            type="password"
            name="password"
            label="Password"
            errors={errors}
            required={true}
            value={password}
            onChange={val => this.updateForm('password', val)}
            ref={input => this.passwordInput = input}
            tabIndex={2}
          />
          {Object.keys(errors).length > 0 && <span className="error-note">The email or password you entered is incorrect.</span>}
          <Button type="submit">
            Log In
          </Button>
          <input type="hidden" name="authenticity_token" value={FORM_TOKEN} />
        </form>
        <div className="register">
          <h1>New here?</h1>
          <a href="/register/send_link">Register your account</a>
        </div>
      </div>
    )
  }
}
