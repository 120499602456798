import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import * as CurrentUserContext from 'current_user_context';
import * as WindowSizeContext from 'window_size_context';
import * as PermissionsContext from 'permissions_context';
import * as AddAdminContext from 'add_admin_context';
import * as PermissionSelectionContext from 'permission_selection_context';
import * as DocCenterContext from 'doc_center_context';
import * as BeneficiaryContext from 'beneficiary_context';
import * as RuleContext from 'rule_context';
import * as NewEmployeeContext from 'new_employee_context';
import * as ShowNavContext from 'show_nav_context';
import * as RequestAProviderContext from './request_a_provider_context';
import * as ClaimChargesCSVContext from './claim_charges_csv_context';
import * as FeatureFlagContext from './feature_flag_context';
import * as ShowAdvancedSearchContext from './show_advanced_search_context';
import * as PopoverContext from './popover_context';

const CONTEXTS = [
  CurrentUserContext,
  WindowSizeContext,
  PermissionsContext,
  AddAdminContext,
  PermissionSelectionContext,
  DocCenterContext,
  BeneficiaryContext,
  RuleContext,
  NewEmployeeContext,
  RequestAProviderContext,
  ClaimChargesCSVContext,
  ShowNavContext,
  FeatureFlagContext,
  ShowAdvancedSearchContext,
  PopoverContext,
];

export const MasterContext = createContext();

export default function MasterProviderWrapper(props) {
  let providerTree = (
    <MasterProvider contexts={props.contexts}>
      {props.children}
    </MasterProvider>
  );

  props.contexts.forEach(context => {
    providerTree = (
      <context.default {...props.values[context.contextName]}>
        {providerTree}
      </context.default>
    );
  })

  return providerTree;
}

MasterProviderWrapper.propTypes = {
  children: PropTypes.node,
  contexts: PropTypes.array,
  values: PropTypes.object,
}

MasterProviderWrapper.defaultProps = {
  contexts: CONTEXTS,
  values: {},
}

function MasterProvider(props) {
  let values = {};
  props.contexts.forEach(mainContext => {
    const { contextName } = mainContext;
    const contextValues = useContext(mainContext[contextName]);

    values = {
      ...values,
      [contextName]: {
        ...contextValues,
      }
    };
  });

  return (
    <MasterContext.Provider value={values}>
      {props.children}
    </MasterContext.Provider>
  );
}

MasterProvider.propTypes = {
  children: PropTypes.node,
  contexts: PropTypes.array,
}
