export default function currency(centsAsInteger, includeSign = true, includeCents = true, includeNegative = true) {
  // If values being passed into function is just a string, ignore and return
  // eg. Dental Annual Max "Unlimited"
  if (typeof centsAsInteger === "string") {
    return centsAsInteger;
  }

  const isNegative = centsAsInteger < 0;
  let dollars = Math.abs(centsAsInteger) / 100.0;

  if (includeCents) {
    dollars = dollars.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  } else {
    dollars = Math.round(dollars)
  }

  const parts = dollars.toString().split(".");
  parts[0] = formatNumberCommas(parts[0]);
  dollars = parts.join(".");

  const res = includeSign ? `$${dollars}` : dollars;
  return isNegative && includeNegative ? `-${res}` : res
}

export function formatCurrency(num) {
  if (num === null || num === undefined || num === '') return '';

  const numString = num.toString();

  const minus = (numString[0] === "-" ? "-" : "");

  let parts = numString.replace(/[^0-9.]/g, "").split(".");

  if (parts.length > 2) {
    parts = [parts[0], parts[1]];
  }

  if (parts[0] !== "") {
    parts[0] = formatNumberCommas(parts[0]);
  }

  return [minus, parts.join(".")].join("");
}

function formatNumberCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatAnnualMax(amount) {
  if (typeof amount !== "string" || amount.includes("$") || amount.match(/\d/g) === null) {
    return amount;
  } else {
    return Number(amount.match(/\d/g).join(""));
  }
}

export function currencyWithoutCommas(centsAsInteger, includeSign = true, includeCents = true, includeNegative = true) {
  const withCommas = currency(centsAsInteger, includeSign, includeCents, includeNegative);

  return withCommas.replace(/,/g, "");
}

export function parseCurrency(dollarsString) {
  const dollars = Number(dollarsString.replace(/[^0-9.]/g, ''));
  return Math.round(dollars * 100.0)
}
