import { createContext, useState } from 'react';
import { useOutsideClick } from '../hooks/general_hooks';
import PropTypes            from 'prop-types';

export const contextName = 'PopoverContext';
export const PopoverContext = createContext();

export default function PopoverProvider({ children }) {
  const [popover, setPopover] = useState();

  const triggerPopover = (component) => setPopover(component);
  const clearPopover = () => setPopover();

  const ref = useOutsideClick(() => clearPopover());

  return (
    <PopoverContext.Provider value={{ ref, popover, triggerPopover, clearPopover }}>
      {children}
    </PopoverContext.Provider>
  );
}

PopoverProvider.propTypes = {
  children: PropTypes.node,
}
