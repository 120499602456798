import "./NavLink.scss"

import { Component } from 'react';
import PropTypes            from 'prop-types'
import classnames           from 'classnames'
import { kebabCase } from 'lodash';

export default class NavLink extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    children: PropTypes.node,
    isInternal: PropTypes.bool,
    active: PropTypes.bool,
    expanded: PropTypes.bool,
  }

  render() {
    const { children, text, url, isInternal, active, expanded } = this.props;
    const linkClasses = classnames('NavLink', kebabCase(text), {
      internal: isInternal
    }, { active: active && expanded });

    const iconClasses = classnames('icon', { active: active && !expanded })

    return (
      <a href={url} className={linkClasses}>
        <div className={iconClasses}>
          {children}
        </div>
        <span className="link-text">{text}</span>
      </a>
    )
  }
}
