import './RegisterLoginInfo.scss'

import { Component } from 'react';
import PropTypes            from 'prop-types'

import Input        from 'Input'
import Button       from 'Button'

import _ from 'lodash'

export default class RegisterLoginInfo extends Component {
  static propTypes = {
    email: PropTypes.string,
    errors: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    handleSubmitEnabled: PropTypes.bool,
    password: PropTypes.string.isRequired,
    passwordConfirmation: PropTypes.string.isRequired,
    updateForm: PropTypes.func.isRequired,
  }

  static defaultProps = {
    email: '',
    password: '',
    passwordConfirmation: '',
    handleSubmitEnabled: true,
  }

  validToSubmit() {
    const { email, password, passwordConfirmation, handleSubmitEnabled } = this.props;

    return !(email.length > 0 && password.length > 0 && passwordConfirmation.length > 0) || !handleSubmitEnabled;
  }

  render() {
    const {
      email,
      errors,
      handleSubmit,
      password,
      passwordConfirmation,
      updateForm,
    } = this.props;

    return (
      <div className="RegisterLoginInfo" data-testid="register-login-info">
        <h1>Register your account</h1>
        <p className="instructions">
          Get access to features and tools that will help you get the most out of your health benefits.
        </p>
        <Input
          type="text"
          name="email"
          label="Email Address"
          errors={errors}
          value={email}
          onChange={val => updateForm('email', val)}
          autocomplete={false}
        />
        <span className="registration-note">Members, use a personal email address so you always have access to your account, even if your employment changes. Admins may want to use a work email address.</span>

        <div className="RegisterPassword" data-testid="register-password">
          <Input
            type="password"
            name="password"
            label="Password"
            hintText="At least 8 characters with 1 letter and 1 number"
            value={password}
            required={true}
            showForgotPasswordLink={false}
            errors={errors}
            onChange={val => updateForm('password', val)}
          />

          <Input
            type="password"
            name="password_confirmation"
            label="Password Confirmation"
            value={passwordConfirmation}
            required={true}
            showForgotPasswordLink={false}
            errors={errors}
            onChange={val => updateForm('passwordConfirmation', val)}
          />
        </div>

        <div>
          <div className="terms">
            By registering your account you agree to the <a href="https://sanabenefits.com/terms-of-service" target="_blank">Terms of Use</a> & <a href="https://sanabenefits.com/privacy-policy" target="_blank">Privacy Policy</a>
          </div>

          <Button onClick={_.debounce(handleSubmit, 200)} disabled={this.validToSubmit()}>
            Register
          </Button>
        </div>
      </div>
    )
  }
}
