import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const contextName = "ShowAdvancedSearchContext";
export const ShowAdvancedSearchContext = createContext();

export default function ShowAdvancedSearch(props) {
  const [showMoreNav, setShowMoreNav] = useState(false);

  const handleToggleMoreNav = (e) => {
    e.preventDefault();
    setShowMoreNav(!showMoreNav);
  };

  const listenForHideMoreNav = (e) => {
    const KEYCODE_ESC = 27;
    if (e.keyCode === KEYCODE_ESC) {
      setShowMoreNav(false);
    }
  };

  return (
    <ShowAdvancedSearchContext.Provider
      value={{
        showMoreNav,
        handleToggleMoreNav,
        listenForHideMoreNav,
      }}
    >
      {props.children}
    </ShowAdvancedSearchContext.Provider>
  );
}

ShowAdvancedSearch.propTypes = {
  children: PropTypes.node,
};
