import "./FlashMessage.scss"

import { Component } from 'react';
import PropTypes            from 'prop-types'
import classnames           from 'classnames'

import CloseIcon from './x.svg'

export default class FlashMessage extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['error', 'success', 'notice']).isRequired,
    children: PropTypes.any.isRequired,
    onClose: PropTypes.func
  }

  render() {
    const { type, onClose, children } = this.props
    const classes = classnames("FlashMessage", type)

    return (
      <div className={classes}>
        <div className="value">{children}</div>
        {(() => {
          if (onClose) {
            return (
              <button className="close" onClick={onClose}>
                <CloseIcon className="close-icon" />
              </button>
            )
          }
        })()}
      </div>
    )
  }
}
