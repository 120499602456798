import "./FormattingInput.scss";

import { Component } from 'react';
import PropTypes            from 'prop-types';
import { generate }         from 'shortid';
import { formatCurrency }   from 'currency';
import classnames           from 'classnames';

import Input        from 'Input';

export default class FormattingInput extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['phone', 'ssn', 'currency', 'tin']),
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
  }

  static defaultProps = {
    type: 'phone',
    className: '',
  }

  state = {
    inputId: `input_${generate()}`,
    type: this.props.type,
  }

  formatInput = (value, type) => {
    switch (type) {
      case 'ssn' || 'tin':
        return this.formatToSsnOrTin(value);
      case 'phone':
        return this.formatToPhone(value);
      case 'currency':
        return formatCurrency(value);
      default:
        return value;
    }
  }

  handleChange = (val, event) => {
    const { onChange, type } = this.props;
    this.enforceFormat(event);

    const shouldFormat = (((type === 'ssn' || type === 'tin' || type === 'phone') && !this.isModifierKey(event))
                            || type === 'currency');

    let value = val;
    if (shouldFormat)
      value = this.formatInput(event.target.value, type);

    if (onChange) {
      onChange(value);
    }
  }

  isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };

  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)){
      event.preventDefault();
    }
  };

  formatToSsnOrTin = (value) => {
    const input = value.replace(/\D/g, '').substring(0, 9);
    const first = input.substring(0, 3);
    const middle = input.substring(3, 5);
    const last = input.substring(5, 9);

    if (input.length > 5) {
      return `${first}-${middle}-${last}`;
    } else if (input.length > 3) {
      return `${first}-${middle}`;
    } else if (input.length > 0) {
      return `${first}`;
    }
  };

  formatToPhone = (value) => {
    const input = value.replace(/\D/g, '').substring(0, 10);
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      return `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      return `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      return `(${zip}`;
    } else {
      return '';
    }
  };

  render() {
    const { type, value, label, helperText } = this.props;

    const classes = classnames(
      'FormattingInput',
      {'currency': type === 'currency'},
    );

    const inputValue = value ?? '';
    let inputType = 'text';
    let inputMode;
    if (type === 'ssn') {
      inputType = 'password';
    } else if (type === 'tin') {
      inputType = 'text';
      inputMode = 'numeric';
    } else if (type === 'phone') {
      inputType = 'text';
      inputMode = 'numeric';
    } else if (type === 'currency') {
      inputType = 'text';
      inputMode = 'numeric';
    }

    const dollarClasses = classnames('dollar-sign', { 'dollar-sign-with-label': !!label });

    return (
      <div className={classes}>
        {type === 'currency' &&
          <span className={dollarClasses}>$</span>
        }
        <Input
          {...this.props}
          type={inputType}
          inputMode={inputMode}
          onChange={this.handleChange}
          value={this.formatInput(inputValue, type)}
          showForgotPasswordLink={false}
        />
        {helperText && <p className="helper-text">{helperText}</p>}
      </div>
    )
  }
}
