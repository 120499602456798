import EventEmitter from 'eventemitter3'
import { generate } from 'shortid'

let flashes = [];
const CHANGE_EVENT = 'FLASH_STORE_UPDATED';

class FlashStore extends EventEmitter {
  getAll() {
    return flashes;
  }

  add(type, message, onDismiss) {
    const key = generate()
    flashes = [{ message, type, onDismiss, key }, ...flashes];
    this.emitChange()
  }

  dismiss(key) {
    const index = flashes.findIndex(f => f.key === key)
    const callback = flashes[index].onDismiss;
    flashes = [
      ...flashes.slice(0, index),
      ...flashes.slice(index + 1)
    ];
    this.emitChange()
    if (callback) callback()
  }

  dismissAll() {
    this.getAll().forEach(flash => this.dismiss(flash.key))
  }

  emitChange() {
    this.emit(CHANGE_EVENT)
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback)
  }
}

export default new FlashStore();
