import './NpsScoreView.scss'

import { useRef } from 'react';
import PropTypes              from 'prop-types';
import { useCheckboxChecked } from 'general_hooks';
import { put }                from 'ajax';
import Checkbox               from 'Checkbox';
import Button                 from 'Button';
import FlashStore             from 'flash_store';

export default function NpsScoreView(props) {
  const { tokenUuid, message } = props;
  const [isChecked, handleCheckboxClick] = useCheckboxChecked();
  const [submitted, toggleSubmitted] = useCheckboxChecked();
  const inputEl = useRef(null);
  const handleSubmit = () => {
    const data = {
      npsScore: {
        notes: inputEl.current.value,
        permittedToContact: isChecked,
      }
    };

    put(`/nps/${tokenUuid}`, data, (res) => {
      if (res.success) {
        toggleSubmitted();
      } else {
        FlashStore.add('error', 'There was an error submitting your comment.');
      }
    });
  }

  return (
    <div className="NpsScoreView">
      {!submitted && tokenUuid &&
        <>
          <h2 className="nps-header">Your score has been submitted.</h2>
          <span className="nps-question">What is the most important reason for your score?</span>
          <textarea name="feedback" className="feedback-input" ref={inputEl} onChange={() => console.log(inputEl)} />
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxClick}
            className="nps-checkbox"
            label="I'm happy to chat more about how to make Sana better."
            name="make-sana-better"
          />
          <Button
            className="nps-submit-btn"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </>
      }
      {submitted && tokenUuid &&
        <NoteContainer
          header="Thanks for letting us know!"
          note="We use this kind of feedback to make your experience better."
        />
      }
      {!tokenUuid &&
        <NoteContainer
          header="There was an issue with the token requested."
          note={message}
        />
      }
    </div>
  )
}

NpsScoreView.propTypes = {
  tokenUuid: PropTypes.string,
  message: PropTypes.string,
};

function NoteContainer(props) {
  const { header, note } = props;

  return (
    <div className="note-container">
      <h2 className="nps-header">{header}</h2>
      <span className="nps-note">{note}</span>
    </div>
  );
}

NoteContainer.propTypes = {
  header: PropTypes.string,
  note: PropTypes.string,
};
