import "./Input.scss";

import { Component } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { generate } from 'shortid';
import classnames from 'classnames';

import Icon from 'Icon';
import ErrorMessage from 'ErrorMessage';
import Tooltip from 'Tooltip';
import QuestionIcon from 'images/question.svg'

export default class Input extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['text', 'date', 'email', 'number', 'password', 'search']),
    inputMode: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    hintText: PropTypes.string,
    tooltip: PropTypes.object,
    errors: PropTypes.object,
    errorKey: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    showForgotPasswordLink: PropTypes.bool,
    forgotPasswordRedirectKey: PropTypes.string,
    tabIndex: PropTypes.number,
    autoFocus: PropTypes.bool,
    step: PropTypes.string,
    formTarget: PropTypes.string,
    isMono: PropTypes.bool,
    autocomplete: PropTypes.any,
    allowWhitespace: PropTypes.bool,
    id: PropTypes.string,
    'data-testid': PropTypes.string,
  }

  static defaultProps = {
    type: 'text',
    className: '',
    showForgotPasswordLink: true,
    disabled: false,
    required: true,
    autoFocus: false,
    isMono: false,
    allowWhitespace: true,
    id: null,
  }

  state = {
    inputId: `input_${generate()}`,
    type: this.props.type,
    toggleText: 'Show'
  }

  hasErrors() {
    return this.props.errors &&
      this.props.errors[this.props.errorKey || this.props.name] &&
      this.props.errors[this.props.errorKey || this.props.name].length > 0;
  }

  handleChange = event => {
    const { onChange, allowWhitespace } = this.props;
    let val = event.target.value;

    if (!allowWhitespace) {
      val = val.trim();
    }

    if (onChange) {
      onChange(val, event);
    }
  }

  handleBlur = event => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event.target.value, event);
    }
  }

  handleTogglePassword = () => {
    if (this.state.type === 'password') {
      this.setState({ type: 'text', toggleText: 'Hide' });
    } else {
      this.setState({ type: 'password', toggleText: 'Show' });
    }
  }

  render() {
    const {
      label, type, inputMode, name, value, placeholder, pattern,
      className, errors, errorKey, disabled, required, tabIndex,
      showForgotPasswordLink, forgotPasswordRedirectKey, hintText,
      tooltip, autocomplete,
      autoFocus, step, formTarget, isMono,
      id,
    } = this.props;


    const forgotPasswordLink = forgotPasswordRedirectKey ? `/forgot-password?redirect=${forgotPasswordRedirectKey}` : "/forgot-password";

    const { inputId } = this.state;

    const classes = classnames("Input", className, {
      disabled,
      required,
      errors: this.hasErrors(),
    })

    const inputClasses = classnames({ "monospaced": isMono });

    const inputValue = value === null || value === undefined ? '' : value;

    return (
      <div className={classes}>
        {type === 'search' &&
          <Icon className="search-icon" name="search" />
        }
        {(type === 'password' && showForgotPasswordLink) &&
          <Link className="forgot-password-link" to={forgotPasswordLink}>Forgot?</Link>
        }
        {label &&
          <label htmlFor={id || inputId}>
            {label}
            {!required &&
              <span className="optional">(optional)</span>
            }
          </label>
        }

        {tooltip &&
          <Tooltip
            small={true}
            position="top"
            noTriggerUnderline={true}
            {...tooltip}
          >
            <QuestionIcon />
          </Tooltip>
        }

        <input
          type={this.state.type}
          inputMode={inputMode}
          name={name}
          id={id || inputId}
          value={inputValue}
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          ref={input => this.input = input}
          readOnly={disabled}
          tabIndex={tabIndex}
          pattern={pattern}
          autoFocus={autoFocus}
          step={step}
          form={formTarget}
          className={inputClasses}
          autoComplete={autocomplete ? autocomplete.toString() : "false"}
          data-testid={this.props['data-testid']}
        />

        {type === 'password' &&
          <span className="toggle-password" onClick={this.handleTogglePassword}>
            {this.state.toggleText}
          </span>
        }

        {this.hasErrors() &&
          <ErrorMessage errors={errors[errorKey || name]} />
        }

        {hintText &&
          <div className="hint-text">{hintText}</div>
        }
      </div>
    )
  }
}
