import { createContext, useState } from 'react';
import PropTypes           from 'prop-types';

export const contextName = 'ShowNavContext';
export const ShowNavContext = createContext();

export default function ShowNavProvider(props) {
  const [showMoreNav, setShowMoreNav] = useState(false);
  const [showMoreNavTimer, setShowMoreNavTimer] = useState(null);
  const [hideMoreNavTimer, setHideMoreNavTimer] = useState(null);

  const handleShowMoreNavAfterDelay = e => {
    e.preventDefault();
    clearTimeout(hideMoreNavTimer);
    const timer = setTimeout(() => setShowMoreNav(true), 450);
    setShowMoreNavTimer(timer);
  }

  const handleHideMoreNavAfterDelay = e => {
    e.preventDefault();
    clearTimeout(showMoreNavTimer);
    const timer = setTimeout(() => setShowMoreNav(false), 300);
    setHideMoreNavTimer(timer);
  }

  const handleToggleMoreNav = e => {
    e.preventDefault();
    clearTimeout(showMoreNavTimer);
    clearTimeout(hideMoreNavTimer);
    setShowMoreNav(!showMoreNav);
  }

  const listenForHideMoreNav = (e) => {
    if (e.keyCode === 27) {
      setShowMoreNav(false);
    }
  }

  return (
    <ShowNavContext.Provider
      value={{
        showMoreNav,
        handleShowMoreNavAfterDelay,
        handleHideMoreNavAfterDelay,
        handleToggleMoreNav,
        listenForHideMoreNav,
      }}
    >
      {props.children}
    </ShowNavContext.Provider>
  )
}

ShowNavProvider.propTypes = {
  children: PropTypes.node,
}
