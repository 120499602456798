import './RegisterEmailPassword.scss'

import { Component } from 'react';
import PropTypes            from 'prop-types'

import Input      from 'Input'
import Button               from 'Button'

export default class RegisterEmailPassword extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    passwordConfirmation: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    errors: PropTypes.object
  }

  render() {
    const { email, password, passwordConfirmation, handleSubmit, updateForm, errors } = this.props;
    return (
      <div className="RegisterEmailPassword" data-testid="register-email-password">
        <form>
          <h1>Create your account</h1>

          <p className="instructions">
            Get access to features and tools that will help you get the most out of your health benefits.
          </p>

          <Input
            type="text"
            name="email"
            label="Email"
            errors={errors}
            value={email}
            onChange={val => updateForm('email', val)}
            autocomplete={false}
          />
          <Input
            type="password"
            name="password"
            label="Password"
            hintText="At least 8 characters with 1 letter and 1 number"
            value={password}
            required={true}
            showForgotPasswordLink={false}
            errors={errors}
            onChange={val => updateForm('password', val)}
          />

          <Input
            type="password"
            name="password_confirmation"
            label="Password Confirmation"
            value={passwordConfirmation}
            required={true}
            showForgotPasswordLink={false}
            errors={errors}
            onChange={val => updateForm('passwordConfirmation', val)}
          />
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={!password || !passwordConfirmation}
            dataTestId="register-button"
          >
            Register
          </Button>
        </form>
      </div>
    )
  }
}
