import "./Textlink.scss"
import PropTypes from 'prop-types';

import classnames from 'classnames'
export default function Textlink({
  children,
  dataTestId,
  dataTrackingId,
  icon,
  iconAlignment,
  href,
  target,
  underline = true,
}) {

  const classes = {
    "textlink--icon-left": icon && iconAlignment === "left",
    "textlink--icon-right": icon && iconAlignment === "right",
    "textlink--not-underlined": !underline,
  }

  const classNames = classnames("Textlink", classes)

  return (
    <a
      className={classNames}
      href={href}
      target={target}
      data-testid={dataTestId}
      data-tracking-id={dataTrackingId}
    >
      <span className="textlink__container">
        {children}
        <i className="textlink__icon">{icon}</i>
      </span>
    </a>
  )
}

Textlink.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconAlignment: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dataTrackingId: PropTypes.string,
  dataTestId: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.oneOf(["_blank", "_parent", "_self", "_top"]),
  underline: PropTypes.bool
}
