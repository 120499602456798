import "./CompanySwitcherModal.scss";

import { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import Modal from 'ModalV2';
import Input from 'InputV2';

const CompanySwitcherModal = ({ companies, visible, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const showCompanySearch = companies?.length > 5;

  const handleDismiss = () => {
    onClose();

    const hasMultipleCompanies = companies?.length > 1;
    if (hasMultipleCompanies) setSearchTerm('');
  }

  const renderCompanies = () => {
    const filteredcompanies = companies?.filter(company => {
      return company.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return filteredcompanies?.map((company) => {
      const url = `/switch-company/${company.uuid}`
      return (
        <a className="CompanySwitcherModal__company-link" href={url} key={company.uuid} >
          <div className="CompanySwitcherModal__company">
            <div className="CompanySwitcherModal__company-name">
              {company.shortName || company.name}
            </div>
            <div className="CompanySwitcherModal__company-attributes">
              <span className="CompanySwitcherModal__group-number">  { company.groupNumber ? `Group ${company.groupNumber}` : '-' } </span>
              <span className="CompanySwitcherModal__renewal-date">
                { company.activeSanaEndDate ? `Renewal: ${moment(company.activeSanaEndDate).add(1, 'd').format('MM/DD/YYYY')}` : '' }
              </span>
            </div>
          </div>
        </a>
      )
    })
  }

  return (
    <Modal
      visible={visible}
      onDismiss={handleDismiss}
      className="CompanySwticherModal"
      size="sm"
      heading="Select a company below to access admin tools and view more"
    >
      <div className="CompanySwitcherModal__container">
        { showCompanySearch &&
          <Input
            type="text"
            label="Search Companies"
            placeholder={`Search ${companies.length} companies`}
            value={searchTerm}
            onChange={val => setSearchTerm(val)}
          />
        }

        {renderCompanies()}
      </div>
    </Modal>
  );
};

CompanySwitcherModal.propTypes = {
  companies: PropTypes.array,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CompanySwitcherModal;
