import './RegisterMerge.scss'

import { Component } from 'react';
import PropTypes            from 'prop-types'

import Input        from 'Input'
import Button       from 'Button'

export default class RegisterMerge extends Component {
  static propTypes = {
    person: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      registrationToken: PropTypes.string.isRequired
    }),
    handleSubmit: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    errors: PropTypes.object,
    passwordSecondTime: PropTypes.bool
  }

  render() {
    const { handleSubmit, updateForm, person, password, errors, passwordSecondTime } = this.props;

    return (
      <div className="RegisterMerge">
        <form>
          {(() => {
            if (passwordSecondTime) {
              return (
                <h1>Enter your new password</h1>
              )
            } else {
              return (
                <h1>Welcome back!</h1>
              )
            }
          })()}

          {(() => {
            if (passwordSecondTime) {
              return (
                <p className="instructions">
                  After you use the link in your email to reset your password, enter your new password below.
                </p>
              )
            } else {
              return (
                <p className="instructions">
                  An account has already been registered using this email address.  Enter your password to continue.
                </p>
              )
            }
          })()}

          <Input
            type="password"
            name="password"
            label="Password"
            forgotPasswordRedirectKey={person.registrationToken}
            value={password}
            errors={errors}
            onChange={val => updateForm('password', val)}
          />

          <Button type="submit" onClick={handleSubmit} disabled={!password}>
            Log in
          </Button>

        </form>
      </div>
    )

  }

}
