import "./Form.scss";

import { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';

import Input from 'Input';

export default class Form extends Component {
  static propTypes = {
    children: PropTypes.any,
    action: PropTypes.string,
    method: PropTypes.oneOf(['POST', 'GET', 'PUT']),
    errors: PropTypes.object,
    objectName: PropTypes.string,
    onSubmit: PropTypes.func,
    className: PropTypes.string
  }

  static defaultProps = {
    method: 'POST'
  }

  handleSubmit = e => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(e);
    }
  }

  childrenWithErrors() {
    const {
      errors,
      children,
      objectName
    } = this.props;

    return Children.map(children, child => {
      if (child && child.type === Input) {
        // add error messages
        const addedProps = { errors }
        // automatically add errorKey if name is wrapped in an objectName
        // ex: "user[email]" becomes "user"
        if (objectName && !child.props.errorKey) {
          addedProps.errorKey = child.props.name
            .replace(`${objectName}[`, '')
            .slice(0, -1)
        }
        return cloneElement(child, addedProps);
      } else {
        return child;
      }
    });
  }

  render() {
    const {
      action,
      method,
      className
    } = this.props;

    return (
      <form
        className={`Form ${className}`}
        action={action} method={method}
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="authenticity_token" value={FORM_TOKEN} />
        {this.childrenWithErrors()}
      </form>
    )
  }
}
