import { createContext, Component } from 'react';
import PropTypes            from 'prop-types';
import { get }              from 'ajax';

export const contextName = 'BeneficiaryContext';
export const BeneficiaryContext = createContext();

export default class BeneficiaryProvider extends Component {
  state = {
    people: [],
    organizations: [],
    trusts: [],
  }

  getData = (enrollmentType) => {
    get('/beneficiaries', {enrollmentType: enrollmentType}, res => {
      this.setState({
        people: res.people,
        organizations: res.organizations,
        trusts: res.trusts,
      });
    });
  }

  addEntity = (entity, item) => {
    this.setState({
      [entity]: [...this.state[entity], item],
    });
  }

  render() {
    return (
      <BeneficiaryContext.Provider
        value={{
          ...this.state,
          getData: this.getData,
          addEntity: this.addEntity,
        }}
      >
        {this.props.children}
      </BeneficiaryContext.Provider>
    )
  }
}

BeneficiaryProvider.propTypes = {
  children: PropTypes.node,
}
