import "./Button.scss";

import { Component } from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

export default class Button extends Component {
  static propTypes = {
    className: PropTypes.string,
    dataTrackingId: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'link', 'a']),
    appearance: PropTypes.oneOf(['red', 'blue']),
    to: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.oneOf(['_blank', '_self']),
    onClick: PropTypes.func,
    children: PropTypes.node,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonRef: PropTypes.object,
    small: PropTypes.bool,
    secondary: PropTypes.bool,
    dataTestId: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    type: 'button',
    appearance: 'red',
    target: '_self',
    small: false,
    secondary: false,
  }

  render() {
    const {
      type,
      className,
      style,
      onClick,
      children,
      loading,
      disabled,
      appearance,
      to,
      href,
      target,
      small,
      secondary,
      buttonRef,
      dataTestId,
      dataTrackingId,
    } = this.props;

    const classes = classnames('Button', className, appearance, {
      loading,
      small,
      secondary,
    })

    if (type === 'link') {
      return (
        <Link
          className={classes}
          to={to}
          disabled={loading || disabled}
          ref={buttonRef}
          style={style}
          data-tracking-id={dataTrackingId}
        >
          {children}
        </Link>
      )
    } else if (type === 'a') {
      return (
        <a
          className={classes}
          href={href}
          target={target}
          ref={buttonRef}
          style={style}
          onClick={onClick}
          data-tracking-id={dataTrackingId}
        >
          {children}
        </a>
      )
    } else {
      return (
        <button
          type={type}
          className={classes}
          onClick={onClick}
          disabled={loading || disabled}
          ref={buttonRef}
          data-testid={dataTestId}
          data-tracking-id={dataTrackingId}
          style={style}
        >
          {children}
        </button>
      )
    }
  }
}
