function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('accessibility-mode');
    window.removeEventListener('keydown', handleFirstTab);
    window.addEventListener('mousedown', handleMouseDownOnce);
  }
}

function handleMouseDownOnce() {
  document.body.classList.remove('accessibility-mode');

  window.removeEventListener('mousedown', handleMouseDownOnce);
  window.addEventListener('keydown', handleFirstTab)
}

export default function addAccessibilityListeners() {
  window.addEventListener('keydown', handleFirstTab);
}
