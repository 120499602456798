import { createContext, Component } from 'react';
import PropTypes            from 'prop-types';
import { post }             from 'ajax';

export const contextName = 'PermissionSelectionContext';
export const PermissionSelectionContext = createContext();

export default class PermissionSelectionProvider extends Component {
  static propTypes = {
    children: PropTypes.object,
  }

  constructor() {
    super();
    this.state = {
      editAdminPermissions: {},
      manageEmployees: {},
      manageEnrollments: {},
      viewEmployees: {},
      viewInvoices: {},
      viewPlanSpend: {},
      admins: [],
    }
  }

  initData = () => {
    if (INITIAL_DATA.permissionsMap && INITIAL_DATA.admins) {
      this.setState({
        editAdminPermissions: INITIAL_DATA.permissionsMap.editAdminPermissions,
        manageEmployees: INITIAL_DATA.permissionsMap.manageEmployees,
        manageEnrollments: INITIAL_DATA.permissionsMap.manageEnrollments,
        viewEmployees: INITIAL_DATA.permissionsMap.viewEmployees,
        viewInvoices: INITIAL_DATA.permissionsMap.viewInvoices,
        viewPlanSpend: INITIAL_DATA.permissionsMap.viewPlanSpend,
        admins: this.alphabetizeAdmins(),
      });
    }
  }

  alphabetizeAdmins = () => {
    return INITIAL_DATA.admins.sort((a, b) => {
      const textA = a.lastName.toUpperCase();
      const textB = b.lastName.toUpperCase();
      if (textA < textB) return -1;
      if (textA > textB) return 1;
      return 0;
    });
  }

  updatePermission = (permissionName, adminUuid, dependents = []) => {
    const map = {...this.state[permissionName]};
    map[adminUuid] = !map[adminUuid]

    if (map[adminUuid]) {
      dependents.forEach(dependentPermission => this.updateDependentPermission(dependentPermission, adminUuid));
    }

    this.setState({
      [permissionName]: map,
    });
  }

  updateDependentPermission = (permissionName, adminUuid) => {
    const map = {...this.state[permissionName]};
    map[adminUuid] = true;
    this.setState({
      [permissionName]: map,
    });
  }

  savePermissions = (permissions, cb, removedAdmins = []) => {
    const permsMap = {};
    permissions.forEach(permissionName => {
      permsMap[permissionName] = this.state[permissionName];
    });

    const data = {
      removedAdmins,
      permissions: permsMap,
    }

    post('/permissions', data, (res) => {
      this.updateAdminsAndPermissions(res.admins, res.permissionsMap);
      cb();
    });
  }

  updateAdminsAndPermissions = (admins, permissions) => {
    this.setState({
      ...permissions,
      admins,
    });
  }

  get permissionsMap() {
    return {
      editAdminPermissions: this.state.editAdminPermissions,
      manageEmployees: this.state.manageEmployees,
      manageEnrollments: this.state.manageEnrollments,
      viewEmployees: this.state.viewEmployees,
      viewInvoices: this.state.viewInvoices,
      viewPlanSpend: this.state.viewPlanSpend,
    };
  }

  get admins() {
    return this.state.admins;
  }

  render() {
    return (
      <PermissionSelectionContext.Provider
        value={{
          permissionsMap: this.permissionsMap,
          admins: this.admins,
          savePermissions: this.savePermissions,
          updatePermission: this.updatePermission,
          updateAdminsAndPermissions: this.updateAdminsAndPermissions,
          initData: this.initData,
        }}
      >
        {this.props.children}
      </PermissionSelectionContext.Provider>
    )
  }
}
