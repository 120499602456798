import "./LoadingSpinner.scss"

import { Component } from 'react';
import PropTypes            from 'prop-types'
import classnames from 'classnames'

export default class LoadingSpinner extends Component {
  static propTypes = {
    className: PropTypes.string,
    small: PropTypes.bool
  }

  static defaultProps = {
    className: "",
    small: false
  }

  render() {
    const {
      className,
      small
    } = this.props;

    const classes = classnames('LoadingSpinner', className, {small})

    return (
      <div className={classes} data-testid="loading-spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    )
  }
}
