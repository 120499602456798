import _ from 'lodash';

const snakeCaseDotted = str => _.join(
  _.map(
    _.split(str, '.'),
    _.snakeCase
  ),
  '.');

export function getUnderscoredKeyMap(camelizedMap) {
  // HACKHACK - this needs to be done since the option values are use verbatim in the backend
  return _.fromPairs(_.map(camelizedMap, (value, key) => [snakeCaseDotted(key), value]));
}
