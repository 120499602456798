import { createContext, Component } from 'react';
import _                   from 'lodash';
import PropTypes           from 'prop-types';
import { get, post }       from 'ajax';

import { isMember } from "provider_request_helpers";

export const contextName = 'RequestAProviderContext';
export const RequestAProviderContext = createContext();

const EMAIL_REGEX = /.+\@.+\..+/;
const EMAIL_ERROR_STRING = 'Invalid email address';

export default class RequestAProviderProvider extends Component {
  static propTypes = {
    newProviderRequest: PropTypes.object,
    children: PropTypes.node,
  }

  DEFAULT_STATE = {
    providerService: null,
    providerName: null,
    officeName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    officePhone: null,
    selectedPatients: [],
    anythingElseInfo: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      patients: [],
      newProviderRequest: props.newProviderRequest || this.DEFAULT_STATE,
      hasUpcomingOrActiveEnrollment: false,
      errors: {},
    }
  }

  fetchData = async (cb) => {
    get('/request-a-provider/new', {}).then(res => {
      const { hasUpcomingOrActiveEnrollment, person, dependents }  = res;

      this.setState({ hasUpcomingOrActiveEnrollment });

      if (hasUpcomingOrActiveEnrollment && person) {
        const patients = [];
        patients.push({
          uuid: person.uuid,
          fullName: person.fullName,
          type: "You",
        });

        dependents.map((dependent) => {
          const { uuid, fullName, firstName, type, age, email, homePhone } =
            dependent;
          patients.push({
            uuid,
            fullName,
            firstName,
            type: _.capitalize(type),
            isOverEighteen: age >= 18,
            email,
            phone: homePhone,
          });
        });

        this.setState({ patients });
      }

      cb(res);
    });
  }

  createNewProviderRequest = (onSuccess, onError) => {
    const params = this.getParams();
    post('/request-a-provider/create', params, (res, status) => {
      if (status === 200) {
        onSuccess();
      } else if (status === 400) {
        const { errors } = res;

        this.setState({ errors });
        if (!_.isEmpty(errors)) {
          onError();
        }
      }
    });
  }

  getParams = () => {
    const {
      providerService, providerName, officeName, address1, address2, city,
      state, zip, officePhone, anythingElseInfo,
    } = this.state.newProviderRequest;


    const providerRequest = {
      provider_info: {
        service: providerService,
        providerName,
        officeName,
        officePhoneNumber: officePhone,
        anythingElse: anythingElseInfo,
      },
      address: {
        address_1: address1,
        address_2: address2,
        city, state, zip,
      },
    };
    providerRequest.patients = this.getSelectedPatientsForRequest();

    const params = {};
    params.providerRequest = providerRequest;

    return params;
  }

  getSelectedProviderService = () => {
    const { providerService } = this.state.newProviderRequest;

    return providerService;
  }

  getSelectedPatientsForRequest = () => {
    const selectedPatients = [...this.state.newProviderRequest.selectedPatients];

    return selectedPatients.map(selectedPatient => {
      const { uuid, email, phone, visited, appointmentDate, blockedByVerification } = selectedPatient;
      const { day, month, year } = appointmentDate;

      const patient = { uuid, email, phone, visited, blockedByVerification };
      return Object.assign(patient, { appointmentDate: _.isEmpty(appointmentDate) ? null : `${year}-${month}-${day}`})
    });
  }

  validateInput = (attr, val) => {
    if (attr === 'email' && !EMAIL_REGEX.test(val)) {
      this.setError('email', [EMAIL_ERROR_STRING]);
    } else {
      this.setError('email', null);
    }
  }

  handleUpdateProviderRequest = (attr, val) => {
    const updates = {};
    updates[attr] = val;
    const newProviderRequest = Object.assign({}, this.state.newProviderRequest, updates)

    this.setState({ newProviderRequest });
  }

  handleBulkUpdateProviderRequest = (fields) => {
    const updates = Object.assign({}, this.state.newProviderRequest, fields);
    this.setState({ newProviderRequest: updates });
  }

  handleAddRemoveSelectedPatient = (selectedPatient) => {
    const selectedPatients = [...this.state.newProviderRequest.selectedPatients];

    const index = selectedPatients.findIndex(patient => patient.uuid === selectedPatient.uuid);
    if (index === -1) {
      const {uuid, fullName, firstName, type, isOverEighteen, phone, email} = selectedPatient;

      selectedPatients.push({
        uuid,
        fullName,
        firstName,
        type,
        appointmentDate: {},
        hasNoScheduledAppointment: false,
        blockedByVerification: false,
        appointmentStatus: null,
        isOverEighteen,
        phone,
        email,
      });
    } else {
      selectedPatients.splice(index, 1);
    }

    this.handleUpdateProviderRequest('selectedPatients', selectedPatients);
  }

  handleUpdateSelectedPatient = (index, attr, val) => {
    const errors = Object.assign({}, this.state.errors);
    const selectedPatients = [...this.state.newProviderRequest.selectedPatients];
    const selectedPatientCopy = { ...selectedPatients[index] }
    selectedPatientCopy[attr] = val;

    if (attr === 'appointmentDate') {
      selectedPatientCopy['hasNoScheduledAppointment'] = false;
    }

    if (attr in errors)
      this.validateInput(attr, val)

    selectedPatients[index] = selectedPatientCopy;
    this.handleUpdateProviderRequest('selectedPatients', selectedPatients);
  }

  handleUpdatePatientAppointmentInfo = (index, attr, val, status) => {
    const selectedPatients = [...this.state.newProviderRequest.selectedPatients];
    const selectedPatientCopy = { ...selectedPatients[index] };
    selectedPatientCopy['appointmentDate'] = {};
    selectedPatientCopy['hasNoScheduledAppointment'] = false;
    selectedPatientCopy['blockedByVerification'] = false;
    selectedPatientCopy['appointmentStatus'] = status;
    if (attr !== 'appointmentScheduled') {
      selectedPatientCopy[attr] = val;
    }

    selectedPatients[index] = selectedPatientCopy;
    this.handleUpdateProviderRequest('selectedPatients', selectedPatients);
  }

  handleUpdateAppointmentDate = (index, part, val) => {
    const updates = {};
    updates[part] = val;

    const selectedPatients = [...this.state.newProviderRequest.selectedPatients];
    const selectedPatient = selectedPatients[index];
    const updatedAppointmentDate = Object.assign({}, selectedPatient.appointmentDate, updates);

    this.handleUpdateSelectedPatient(index, 'appointmentDate', updatedAppointmentDate);
  }

  validateSelectedPatientsAppointments = () => {
    const requiredSteps = ['day', 'month', 'year'];
    const selectedPatients = [...this.state.newProviderRequest.selectedPatients];

    return Object.values(selectedPatients).every(patient => {
      return patient.hasNoScheduledAppointment || patient.blockedByVerification || requiredSteps.every((step) => {
        return step in patient.appointmentDate;
      });
    });
  }

  validateSelectedPatientsVisited = () => {
    const selectedPatients = [...this.state.newProviderRequest.selectedPatients];

    return Object.values(selectedPatients).every(patient => {
      return 'visited' in patient;
    });
  }

  setError = (attr, errorVal) => {
    const updatedErrors = Object.assign({}, this.state.errors);

    if (_.isEmpty(errorVal))
      delete updatedErrors[attr];
    else
      updatedErrors[attr] = errorVal;

    this.setState({ errors: updatedErrors });
  }

  validateForm = (requiredSteps) => {
    const { newProviderRequest, errors } = this.state;

    if (!_.isEmpty(errors))
      return false;

    return requiredSteps.every((step) => {
      switch (step) {
        case 'visited':
          return this.validateSelectedPatientsVisited();
        case 'appointments':
          return this.validateSelectedPatientsAppointments();
        default:
          return (_.isObject(newProviderRequest[step]) && !_.isEmpty(newProviderRequest[step]))
            || (!_.isObject(newProviderRequest[step]) && !!newProviderRequest[step]);
      }
    });
  }

  numberOfDependentsOverEighteen = () => {
    const { selectedPatients } = this.state.newProviderRequest;

    return selectedPatients.filter((patient) => {
      return patient.isOverEighteen && !isMember(patient);
    }).length;
  }

  getPatientIndexOfDependent = (dependentIndex) => {
    const { selectedPatients } = this.state.newProviderRequest;
    let index = dependentIndex;

    for (let i = 0; i < selectedPatients.length; i++) {
      if (selectedPatients[i].isOverEighteen && !isMember(selectedPatients[i])) {
        if (index > 0)
          index--;
        else
          return i;
      }
    }
  }

  clearData = () => {
    this.setState({
      newProviderRequest: {
        ...this.DEFAULT_STATE,
        selectedPatients: []
      },
      patients: [],
      hasUpcomingOrActiveEnrollment: false,
      errors: {}
    });
  }

  render() {
    const { newProviderRequest,
      patients,
      hasUpcomingOrActiveEnrollment,
      errors,
    } = this.state;

    return (
      <RequestAProviderContext.Provider
        value={{
          ...newProviderRequest,
          patients,
          hasUpcomingOrActiveEnrollment,
          errors,
          numberOfDependentsOverEighteen: this.numberOfDependentsOverEighteen,
          getPatientIndexOfDependent: this.getPatientIndexOfDependent,
          fetchData: this.fetchData,
          clearData: this.clearData,
          createNewProviderRequest: this.createNewProviderRequest,
          handleUpdateProviderRequest: this.handleUpdateProviderRequest,
          handleBulkUpdateProviderRequest: this.handleBulkUpdateProviderRequest,
          handleAddRemoveSelectedPatient: this.handleAddRemoveSelectedPatient,
          handleUpdateSelectedPatient: this.handleUpdateSelectedPatient,
          handleUpdateAppointmentDate: this.handleUpdateAppointmentDate,
          handleUpdatePatientAppointmentInfo: this.handleUpdatePatientAppointmentInfo,
          validateForm: this.validateForm,
          validateInput: this.validateInput,
          setError: this.setError,
          getSelectedProviderService: this.getSelectedProviderService,
        }}
      >
        {this.props.children}
      </RequestAProviderContext.Provider>
    )
  }
}
