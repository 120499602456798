import "./NavMenu.scss";

import { useContext, useRef, useEffect } from 'react';
import PropTypes from "prop-types";

import { MasterContext }    from 'master_context';
import MoreIcon from "icons/global/outline_16px/more_vertical_dots_16px.svg";
import BrokerIcon from "icons/global/outline_16px/book_open_16px.svg";
import CompanyIcon from "icons/global/outline_16px/buildings_16px.svg";
import MemberIcon from "icons/global/outline_16px/person_16px.svg";

const menuTitle = Object.freeze({
  "BrokerApp": "Broker View",
  "CompanyAdminApp": "Admin View",
  "MemberPortalApp": "My Sana Account",
});

const NavMenu = ({ activeApp, isAdmin, isBroker }) => {
  const { PopoverContext } = useContext(MasterContext);
  const { triggerPopover } = PopoverContext;

  const handleShowNavMenuPopover = () => {
    triggerPopover(
      <NavMenuPopover
        activeApp={activeApp}
        isAdmin={isAdmin}
        isBroker={isBroker}
      />
    );  
  }

  const handleShowNavMenuPopoverOnKeyDown = (e) => {
    if (e.key !== "Enter" && e.key !== " ") return;
    
    handleShowNavMenuPopover();
  }

  return (
    <div 
      className="NavMenu"
      role="button"
      tabIndex="0"
      onClick={handleShowNavMenuPopover}
      onKeyDown={handleShowNavMenuPopoverOnKeyDown}>
      <div className="NavMenu__menu-text">{menuTitle[activeApp]}</div>
      <div className="NavMenu__menu-hamburger">
        <MoreIcon role="img" aria-labelledby="Switch View" />
      </div>
    </div>
  );
}

const NavMenuPopover = ({ activeApp, isAdmin, isBroker }) => {
  const { PopoverContext } = useContext(MasterContext);
  const { ref } = PopoverContext;

  const firstLinkRef = useRef(null);

  useEffect(() => {
    if (firstLinkRef.current) {
      firstLinkRef.current.focus()
    }
  }, [firstLinkRef])

  const linkData = {
    broker: {
      title: menuTitle["BrokerApp"],
      show: (isBroker && activeApp !== "BrokerApp"),
      link: "/broker/dashboard",
      icon: <BrokerIcon />,
    },
    admin: {
      title: menuTitle["CompanyAdminApp"],
      show: (isAdmin && activeApp !== "CompanyAdminApp"),
      link: "/company/dashboard",
      icon: <CompanyIcon />,
    },
    member: {
      title: menuTitle["MemberPortalApp"],
      show: (activeApp !== "MemberPortalApp"),
      link: "/dashboard",
      icon: <MemberIcon />,
    },
  };

  return (
    <div role="dialog" ref={ref} className="NavMenuPopover">
      {Object.keys(linkData).filter((key) => linkData[key]['show']).map((key, index) => {
        const { title, link, icon } = linkData[key];
        
        const menuItem = (
          <a className="NavMenuPopover__item" key={key} href={link}>
            {icon}
            {title}
          </a>
        );

        if (index === 0) return {...menuItem, ref: firstLinkRef}

        return menuItem;
      })}
    </div>
  );
}

NavMenuPopover.propTypes = {
  activeApp: PropTypes.string,
  isAdmin: PropTypes.bool,
  isBroker: PropTypes.bool,
}

NavMenu.propTypes = {
  activeApp: PropTypes.string,
  isAdmin: PropTypes.bool,
  isBroker: PropTypes.bool,
};

export default NavMenu;
