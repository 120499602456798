import { Component } from 'react';
import FlashStore           from 'flash_store'
import FlashMessage         from 'FlashMessage'

/*------------------------------------------------------------
 * NOTE:
 * This is a stateful component that should only be used
 * in base layout files, not individual views.
 * ----------------------------------------------------------*/

export default class FlashMessageQueue extends Component {
  state = {
    flashes: []
  }

  handleCloseFlash = key => {
    FlashStore.dismiss(key)
  }

  updateFlashState = () => {
    this.setState({
      flashes: FlashStore.getAll()
    })
  }

  componentDidMount() {
    this.updateFlashState()
    FlashStore.addChangeListener(this.updateFlashState)
  }

  componentWillUnmount() {
    FlashStore.removeChangeListener(this.updateFlashState)
  }

  renderMessages = () => {
    const { flashes } = this.state;
    return flashes.map(flash => {
      return (
        <FlashMessage type={flash.type} key={flash.key} onClose={() => this.handleCloseFlash(flash.key)}>
          {flash.message}
        </FlashMessage>
      )
    })
  }

  render() {
    return (
      <div className="FlashMessageQueue">
        {this.renderMessages()}
      </div>
    )
  }
}
