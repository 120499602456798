import { createContext, Component } from 'react';
import PropTypes            from 'prop-types';

export const contextName = 'WindowSizeContext';
export const WindowSizeContext = createContext();

export default class WindowSizeProvider extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    }
  }

  get isMobile() {
    return this.state.width < 768;
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth });
  }

  render() {
    return (
      <WindowSizeContext.Provider
        value={{
          isMobile: this.isMobile,
          width: this.state.width,
          updateWidth: this.updateWidth,
        }}
      >
        {this.props.children}
      </WindowSizeContext.Provider>
    )
  }
}

WindowSizeProvider.propTypes = {
  children: PropTypes.node,
}
