import "./Tooltip.scss"

import { Component } from 'react';
import PropTypes            from 'prop-types'
import { MasterContext }    from 'master_context'
import classnames           from "classnames"

export default class Tooltip extends Component {
  static propTypes = {
    message: PropTypes.any,
    position: PropTypes.oneOf(['top', 'top-right', 'top-left', 'bottom', 'bottom-left', 'left', 'right', 'right-bottom']),
    children: PropTypes.any,
    className: PropTypes.string,
    title: PropTypes.string,
    small: PropTypes.bool,
    noTriggerUnderline: PropTypes.bool,
    variant: PropTypes.oneOf(['default', 'v2']),
  }

  static defaultProps = {
    position: 'top',
    className: "",
    variant: 'default',
  }

  static contextType = MasterContext;

  state = {
    displayTooltip: false
  }

  handleHideTooltip = () => {
    const { WindowSizeContext: { isMobile } } = this.context;
    if (isMobile) return;
    this.setState({ displayTooltip: false })

  }
  handleShowTooltip = () => {
    const { WindowSizeContext: { isMobile } } = this.context;
    if (isMobile) return;
    this.setState({ displayTooltip: true })
  }

  handleToggleTooltip = (event) => {
    // Allow clicking on a tooltip on mobile even when inside a linked element
    event.stopPropagation();
    this.setState({ displayTooltip: !this.state.displayTooltip });
  }

  render() {
    const {
      message,
      position,
      className,
      title,
      small,
      noTriggerUnderline,
      variant
    } = this.props;

    const tooltipMessageClasses = classnames(
      'tooltip-message',
      {'small': small},
    )

    const tooltipTriggerClasses = classnames(
      'tooltip-trigger',
      {'no-trigger-underline': noTriggerUnderline},
    )

    return (
      <span className={`Tooltip ${className} ${variant}`} onMouseLeave={this.handleHideTooltip}>
        {this.state.displayTooltip && (
          <div className={`tooltip-bubble tooltip-${position}`}>
            <div className={tooltipMessageClasses}>
              {title && <span className="tooltip-title">{title}</span>}
              {message}
            </div>
          </div>
        )}
        <span className={tooltipTriggerClasses} onMouseOver={this.handleShowTooltip} onClick={this.handleToggleTooltip}>
          {this.props.children}
        </span>
      </span>
    )
  }
}
