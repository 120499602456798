import './RegisterMemberView.scss'

import { Component } from "react";
// import { useLocation }      from "react-router-dom";
import PropTypes            from 'prop-types'
import { post, get }        from 'ajax'

import RegisterLink                          from '../../components/RegisterLink'
import RegisterLoginInfo                     from '../../components/RegisterLoginInfo'
import RegisterMerge                         from '../../components/RegisterMerge'
import RegisterError                         from '../../components/RegisterError'

export default class RegisterMemberView extends Component {
  static propTypes = {
    person: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      registrationToken: PropTypes.string.isRequired,
    }),
    currentStep: PropTypes.string,
    email: PropTypes.string,
    location: PropTypes.shape({
      search: PropTypes.func.isRequired,
    }),
  };

  state = {
    currentStep: this.props.currentStep || "loginInfo",
    email: this.props.email || "",
    password: "",
    passwordConfirmation: "",
    nickname: "",
    paperlessCommunication: true,
    passwordSecondTime: false,
    handleSubmitEnabled: true,
  };

  componentDidMount() {
    if (this.props.email) {
      this.setState({ currentStep: "merge" });
      this.setState({ passwordSecondTime: true });
    }
  }

  updateForm = (attr, val) => {
    const newState = {};
    newState[attr] = val;
    this.setState(newState);
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    const { email, password, currentStep } = this.state;
    const password_confirmation = this.state.passwordConfirmation;

    switch (currentStep) {
      case "link": {
        const jsonData = { email };
        post(window.location.pathname, jsonData, (response, status) => {
          if (response.already_registered) {
            this.setState({ currentStep: "alreadyRegistered" });
          } else {
            this.setState({ currentStep: "linkSubmitted" });
          }
        });
        break;
      }
      case "loginInfo": {
        const jsonData = { email, password, password_confirmation };

        this.setState({ handleSubmitEnabled: false });

        get(`${window.location.pathname}/exists`, jsonData, (response) => {
          if (response.errors) {
            this.setState({ errors: response.errors, handleSubmitEnabled: true });
          } else if (response.exists) {
            this.setState({ currentStep: "merge" });
          } else if (response.already_has_member_at_company) {
            this.setState({ currentStep: "error" });
          }
        });

        post(`${window.location.pathname}/create`, jsonData, (response) => {
          if (response.errors) {
            this.setState({ errors: response.errors });
          } else if (response.success) {
            window.location = "/?finishing_registration=1"
          }
        });

        break;
      }
      case "merge": {
        const jsonData = { email, password };
        const url = `${window.location.pathname}/merge`;
        post(url, jsonData, (response) => {
          if (response.errors) {
            this.setState({ errors: response.errors });
          } else if (response.success) {
            window.location = "/?finishing_registration=1"
          }
        });
        break;
      }
    }
  };

  isRegistered = () => {
    return (
      new URLSearchParams(this.props.location.search).get("registered") === "true"
    );
  };

  render() {
    const {
      email,
      password,
      passwordConfirmation,
      errors,
      passwordSecondTime,
    } = this.state;
    let { currentStep } = this.state;
    const { person } = this.props;

    if (this.isRegistered()) {
      currentStep = "alreadyRegistered";
    }

    let step;

    if (
      currentStep === "link" ||
      currentStep === "linkSubmitted" ||
      currentStep === "alreadyRegistered"
    ) {
      step = (
        <RegisterLink
          email={email}
          person={person}
          errors={errors}
          currentStep={currentStep}
          updateForm={this.updateForm}
          handleSubmit={this.onSubmit}
        />
      );
    } else if (
      currentStep === "loginInfo"
    ) {
      step = (
        <RegisterLoginInfo
          email={email}
          person={person}
          errors={errors}
          updateForm={this.updateForm}
          handleSubmit={this.onSubmit}
          password={password}
          passwordConfirmation={passwordConfirmation}
          handleSubmitEnabled={this.state.handleSubmitEnabled}
        />
      );
    } else if (currentStep === "merge") {
      step = (
        <RegisterMerge
          person={person}
          password={password}
          errors={errors}
          updateForm={this.updateForm}
          handleSubmit={this.onSubmit}
          passwordSecondTime={passwordSecondTime}
        />
      );
    } else if (currentStep === "error") {
      step = (
        <RegisterError />
      );
    }

    return <div className="RegisterMemberView">{step}</div>;
  }
}
