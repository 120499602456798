import "./Checkbox.scss"

import { Component } from 'react';
import PropTypes            from 'prop-types'
import { generate }         from 'shortid';
import classnames           from 'classnames'

import CheckedImage           from 'images/checkbox-selected'
import UncheckedImage         from 'images/checkbox-unselected'
import CheckedDisabledImage   from 'images/check-selected-disabled'
import UncheckedDisabledImage from 'images/check-unselected-disabled'

import ErrorMessage from 'ErrorMessage';
import ErrorIcon from 'images/error';

export default class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    className: PropTypes.string,
    name: PropTypes.string,
    errors: PropTypes.object,
    errorKey: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    disabled: PropTypes.bool,
    updatedStyles: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    checked: false,
    disabled: false,
  }

  state = {
    inputId: `input_${generate()}`
  }

  handleChange = value => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  renderImage = () => {
    const { checked, disabled } = this.props;
    if (checked && disabled) {
      return <CheckedDisabledImage className="checkbox" />
    } else if (checked && !disabled) {
      return <CheckedImage className="checkbox" />
    } else if (!checked && disabled) {
      return <UncheckedDisabledImage className="checkbox" />
    } else {
      return <UncheckedImage className="checkbox" />
    }
  }

  hasErrors() {
    return this.props.errors &&
      this.props.errors[this.props.errorKey || this.props.name] &&
      this.props.errors[this.props.errorKey || this.props.name].length > 0;
  }

  render() {
    const { inputId } = this.state;
    const { label, className, checked, value, title, name, disabled, updatedStyles } = this.props;

    const classes = classnames(
      "Checkbox",
      className,
      {
        checked,
        "content-panel": !!(label || title),
        "updated-styles": updatedStyles,
        errors: this.hasErrors()
      });
    const handleChange = disabled ? null : () => this.handleChange(value);

    return (
      <div className={classes} onClick={handleChange}>
        <input
          type="checkbox"
          name={name}
          value={value}
          id={inputId}
          checked={checked}
          onChange={() => this.handleChange(value)}
          disabled={disabled}
        />

        {this.renderImage()}

        {title && <div className="title">{title}</div>}
        {label !== null && label !== undefined && (
          <div className="label">
            {label}
          </div>
        )}

        { this.hasErrors() &&
          <>
            <ErrorIcon className="error-icon" />
            <ErrorMessage errors={this.props.errors[this.props.errorKey || this.props.name]} />
          </>
        }
      </div>
    )
  }
}
